import React from 'react';
import {
  nameValidation,
  descriptionValidation,
} from '../../utils/addProductValidation';
import { useFormContext } from 'react-hook-form';
import { FormError } from '../common/FormError';
import { useTranslation } from 'react-i18next';
import { Description } from './description';
import Inputs from '../../utils/types/addProductTypes';
interface BasicInfoProps {}

export const BasicInfo: React.FC<BasicInfoProps> = () => {
  const {
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext<Inputs>(); // retrieve all hook methods
  const { t } = useTranslation();
  function onDescriptionChange(value: string) {
    setValue('description', value);
  }
  return (
    <>
      <div className='form-row'>
        <div className='col'>
          <label className='col-form-label d-flex justify-content-between align-items-center add-product-label'>
            {t('products:addProduct.productName')}&nbsp;&nbsp;
            <input
              className='form-control w-75'
              type='text'
              {...register('name', nameValidation)}
            />
          </label>
          {errors.name && <FormError errors={errors} name='name' />}
        </div>
      </div>
      <div className='form-row'>
        <div className='col'>
          <label className='col-form-label d-flex justify-content-between align-items-center add-product-label'>
            {t('products:addProduct.productNameArabic')}
            &nbsp;&nbsp;
            <input
              className='form-control w-75'
              type='text'
              dir='rtl'
              {...register('arabicName', nameValidation)}
            />
          </label>
          {errors.arabicName && <FormError errors={errors} name='arabicName' />}
        </div>
      </div>
      <div className='form-row'>
        <div className='col'>
          <label className='col-form-label d-flex justify-content-between align-items-start add-product-label'>
            {t('products:addProduct.description')}&nbsp;&nbsp;
          </label>
          <Description
            onChange={onDescriptionChange}
            text={getValues('description')}
          />

          {errors.description && (
            <FormError errors={errors} name='description' />
          )}
        </div>
      </div>
    </>
  );
};
