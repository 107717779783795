import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Inputs from '../../utils/types/addProductTypes';
import Config from '../../utils/config';
interface previewProps {
  coverImage: string;
}

export const PreviewEdit: React.FC<previewProps> = ({ coverImage }) => {
  const { t, i18n } = useTranslation();
  const { watch } = useFormContext<Inputs>();

  const [name, price, arabicName] = watch(['name', 'price', 'arabicName']);
  return (
    <>
      {' '}
      <div className='col'>
        <div className='card shadow card-preview'>
          <img
            className='card-img-top w-100 d-block prod-card-img'
            src={
              coverImage.startsWith('blob')
                ? coverImage
                : `https://${Config.REACT_APP_CLOUDFRONT}/${coverImage}`
            }
            alt={`${name}-cover`}
          />
          <div className='card-body p-3'>
            <div>
              <p className='prod-card-title'>
                {i18n.language === 'ar' ? arabicName : name}
              </p>
            </div>
            <div>
              <p className='prod-card-subtitle mb-1'></p>
            </div>
            <div>
              <div>
                <h2 className='text-start mb-3 prod-card-price'>
                  <strong>
                    {t('products:oneProduct.EGP')} {price}
                  </strong>
                </h2>
              </div>
              <div className='text-center'>
                <button
                  className='btn btn-primary prod-card-btn w-75'
                  type='button'
                  disabled
                >
                  <i className='fa fa-cart-plus'></i>&nbsp;
                  {t('products:addProduct.preview.addToCart')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
