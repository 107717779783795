import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/i18n';
import './utils/globals';
import './assets/js/script';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import AuthComponent from './utils/authContext';
import { Router } from 'react-router-dom';
import History from './utils/history';
import { TranslationsWrapper } from './utils/TranslationsWrapper';
import HeartSpinnerGlobal from './components/common/globalSpinnner';
ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<HeartSpinnerGlobal />}>
      <TranslationsWrapper>
        <Router history={History}>
          <AuthComponent>
            <App />
          </AuthComponent>
        </Router>
      </TranslationsWrapper>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
