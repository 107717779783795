enum StyleIDS {
  STYLES = 'stylesCss',
  CANVASS = 'off-canvasCss',
  NAV = 'navCss',
}

interface StyleSheetData {
  id: StyleIDS;
  url: {
    ar: string;
    en: string;
  };
}

const styleSheets: StyleSheetData[] = [
  {
    id: StyleIDS.CANVASS,
    url: {
      ar: `${process.env.REACT_APP_URL}/assets/arabic/css/Off-Canvas-Sidebar-Drawer-Navbar-ar.css`,
      en: `${process.env.REACT_APP_URL}/assets/css/Off-Canvas-Sidebar-Drawer-Navbar.css`,
    },
  },
  {
    id: StyleIDS.NAV,
    url: {
      ar: `${process.env.REACT_APP_URL}/assets/arabic/css/Navigation-Clean-ar.css`,
      en: `${process.env.REACT_APP_URL}/assets/css/Navigation-Clean.css`,
    },
  },
  {
    id: StyleIDS.STYLES,
    url: {
      ar: `${process.env.REACT_APP_URL}/assets/arabic/css/styles-ar.css`,
      en: `${process.env.REACT_APP_URL}/assets/css/styles.css`,
    },
  },
];

class StyleSheetHandler {
  private styleSheets: StyleSheetData[] = styleSheets;
  private cleanStyleSheets = () => {
    styleSheets.forEach((el) => {
      document.getElementById(el.id)?.remove();
    });
  };
  private createPromise = (styleID: string, url: string) => {
    return new Promise<void>((resolve, reject) => {
      let link = document.createElement('link');
      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.id = styleID;
      link.onload = function (e) {
        resolve();
      };
      link.onerror = function (e) {
        reject();
      };
      link.href = url;
      document.head.appendChild(link);
    });
  };
  public changeStyleSheets = async (lang: 'ar' | 'en') => {
    this.cleanStyleSheets();
    const promises = this.styleSheets.map((el) => {
      return this.createPromise(el.id, el.url[lang]);
    });
    try {
      await Promise.all(promises);
    } catch {
      throw new Error('Failed to load styles');
    }
  };
}
export const StyleSheetHandle = new StyleSheetHandler();
