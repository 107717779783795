import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';
import APIFeatures from './axios';
import { LoginRouterState } from './LoginAlerts';
import { Login } from './responses.interface';
import LocalStorageService, {
  UserInfo,
  LocalStorageData,
} from './services/localStorageService';

interface AuthData extends UserInfo {
  isLoggedIn: boolean;
}
interface ContextType {
  AuthState: AuthData;
  Login: (res: Login) => void;
  Logout: VoidFunction;
}

const AuthContext = React.createContext<ContextType>({
  Login: (res: Login) => {},
  AuthState: {
    firstName: null,
    lastName: null,
    companyName: null,
    isLoggedIn: false,
  },
  Logout: () => {},
});

export const AuthComponent: React.FC = ({ children }) => {
  const [AuthState, setAuthState] = useState<AuthData>({
    firstName: null,
    lastName: null,
    companyName: null,
    isLoggedIn: false,
  });
  const [Loading, setLoading] = useState(true);
  const location = useLocation<LoginRouterState>();
  useEffect(() => {
    async function getInitialData() {
      try {
        const requestedData = await APIFeatures.RequestNewRefreshTokenGlobal();
        if (requestedData.userType === 'Vendor') {
          setAuthState({
            firstName: requestedData.vendor.contactPersonFirstName,
            lastName: requestedData.vendor.contactPersonLastName,
            companyName: requestedData.vendor.name,
            isLoggedIn: true,
          });
        }
        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    getInitialData();
  }, []);
  useEffect(() => {
    if (
      location.state !== undefined &&
      location.state !== null &&
      location.state.reason === 'REFRESHTOKEN' &&
      location.state.type === 'Timeout'
    )
      setAuthState({
        firstName: null,
        lastName: null,
        companyName: null,
        isLoggedIn: false,
      });
  }, [location.state]);

  function Login(res: Login) {
    const setObj: LocalStorageData = {
      isLoggedIn: true,
      firstName: res.vendor.contactPersonFirstName,
      lastName: res.vendor.contactPersonLastName,
      companyName: res.vendor.name,
    };
    setAuthState(setObj);
  }
  function Logout() {
    setAuthState({
      firstName: null,
      lastName: null,
      companyName: null,
      isLoggedIn: false,
    });
  }
  return (
    <AuthContext.Provider value={{ Login, Logout, AuthState }}>
      {Loading ? <HeartSpinnerGlobal /> : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthComponent as default, useAuth };
