import React from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../utils/config';
import { ProductAttrs } from '../../utils/models/product';
import { Link } from 'react-router-dom';
interface productTableRowProps {
  product: ProductAttrs;
}

export const ProductTableRow: React.FC<productTableRowProps> = ({
  product,
}) => {
  const { t, i18n } = useTranslation();
  let activity = {
    colour: 'table-status green',
    text: 'ACTIVE',
  };
  if (product.isHidden) {
    activity.colour = 'table-status gray';
    activity.text = 'Hidden';
  }
  return (
    <tr>
      <td className='text-center table-card-cell'>
        <input type='checkbox' />
      </td>
      <td className='d-flex table-card-cell'>
        <div>
          <img
            className='border rounded-0 table-thumb'
            src={`https://${config.REACT_APP_CLOUDFRONT}/${product.coverImage}`}
            alt={`${product.name}-coverimage`}
          />
        </div>
        <div className='d-flex flex-column product-description'>
          <p className='product-table-name m-0'>
            {i18n.language === 'ar' ? product.arabicName : product.name}
          </p>
          <p className='product-table-id m-0'>
            {t('products:oneProduct.SKU')} {product._id}
          </p>
          <p className='product-table-id m-0'>
            {t('products:oneProduct.vendorId')} {product.vendorSku || 'N/A'}
          </p>
        </div>
      </td>
      <td className='table-card-cell'>
        <div className='d-flex flex-column listing-details'>
          <p className='product-table-id'>
            {t('products:oneProduct.quantity')} {product.baseQuantity}{' '}
            {t('products:oneProduct.items')}
          </p>

          <p className='product-table-id'>
            {t('products:oneProduct.offer')} N/A {t('products:oneProduct.EGP')}
          </p>
        </div>
      </td>
      <td className='table-card-cell'>
        <div className={activity.colour}>
          <h6 className='m-0'>{activity.text}</h6>
        </div>
      </td>
      <td className='table-card-cell text-end'>
        <Link
          className='edit-prod-icon'
          to={`/products/edit-product/${product.slug}`}
        >
          <i
            className='material-icons table-row-icon'
            style={{ cursor: 'pointer' }}
          >
            edit
          </i>
        </Link>

        <i className='material-icons table-row-icon'>more_vert</i>
      </td>
    </tr>
  );
};
