import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import APIFeatures from '../utils/axios';
import { LoginError } from '../components/login/error';
import { useAuth } from '../utils/authContext';
import { LoginWarning } from '../components/login/loginWarning';
import { LoginMessages, LoginRouterState } from '../utils/LoginAlerts';
import { LoginSuccess } from '../components/login/loginSuccess';
import { getMessageFromState } from '../utils/helperFunctions';
interface loginProps {}
export interface LoginMessagesState {
  error?: string;
  warning?: string;
  success?: string;
}
interface LoginErrorInt {
  message: string;
  type: string;
  path: string;
}
export const Login: React.FC<loginProps> = (props) => {
  const [loginDetails, setLoginDetails] = useState({
    username: '',
    password: '',
  });
  const { Login } = useAuth();
  const [errorBackend, setErrorBackend] = useState<LoginErrorInt[]>([]);
  const [messages, setMessages] = useState<LoginMessagesState>();
  const history = useHistory();
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setLoginDetails((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }
  const location = useLocation<LoginRouterState>();
  useEffect(() => {
    if (!location.state) return;
    const messages = getMessageFromState(location.state);
    setMessages(messages);
  }, [location]);
  async function handleSubmit() {
    try {
      const res = await APIFeatures.Login(loginDetails);
      if (!res.data.isEmailConfirmed) {
        setMessages({
          warning: LoginMessages.ConfirmEmailAlert,
        });
        return;
      }
      Login(res.data);
      history.push('/');
    } catch (err: unknown) {
      if (axios.isAxiosError(err)) {
        setErrorBackend(err.response?.data.errors);
      }
    }
  }
  return (
    <React.Fragment>
      <div className='shadow login-box'>
        <div className='row m-0'>
          <div className='col d-flex justify-content-center align-items-center mt-2'>
            <img
              className='d-flex img-login-box'
              src='assets/img/ultra%20teb%20final.svg'
              alt='login-logo'
            />
            <div>
              <h1 className='login-title'>
                ULTRA<strong>TEB</strong>
              </h1>
              <h1 className='mt-0 mb-0 sub-login'>
                <em>Vendor</em>
              </h1>
            </div>
          </div>
        </div>
        <div className='row m-0'>
          <div className='col'>
            <div className='col d-inline-flex justify-content-center align-items-center'>
              <i className='fas fa-user-lock'></i>
              <h3 className='login-box-title m-0'>
                <strong>Vendor Login</strong>
              </h3>
            </div>
          </div>
        </div>
        {errorBackend?.length > 0 &&
          errorBackend.map((el, i) => {
            return <LoginError message={el.message} key={i} />;
          })}
        {messages?.warning && <LoginWarning message={messages.warning} />}
        {messages?.success && <LoginSuccess message={messages.success} />}
        {messages?.error && <LoginError message={messages.error} />}

        <div className='row m-0'>
          <div className='col-12'>
            <form>
              <div className='form-group'>
                <input
                  className='form-control login-field mb-3'
                  type='text'
                  placeholder='Username'
                  value={loginDetails.username}
                  name='username'
                  onChange={handleChange}
                />
              </div>
              <div className='form-group'>
                <input
                  className='form-control login-field mb-3'
                  type='password'
                  placeholder='Password'
                  name='password'
                  value={loginDetails.password}
                  onChange={handleChange}
                />
              </div>
            </form>
          </div>
          <div className='col-12 d-flex flex-column justify-content-center align-content-center mb-2'>
            <button
              className='btn btn-primary flex-grow-1 login-btn'
              type='button'
              onClick={handleSubmit}
            >
              <strong>Log In</strong>
            </button>
            <Link className='forgot-pass-link mt-1' to='/forget-password'>
              Forgot Password?
            </Link>
            <Link className='forgot-pass-link' to='/signup'>
              Not a Vendor yet? Register Here.
            </Link>
          </div>
        </div>
      </div>
      <div className='container-fluid p-0'>
        <div className='d-flex login-wrapper'>
          <img
            className='logo-img-login'
            src='assets/img/ultra%20teb%20final.svg'
            alt='logo-login-background'
          />
        </div>
      </div>
    </React.Fragment>
  );
};
