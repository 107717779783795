import React, { useState } from 'react';
import { PublicApiFeatures } from '../utils/axios';
import config from '../utils/config';

interface forgetPasswordProps {}

export const ForgetPassword: React.FC<forgetPasswordProps> = ({}) => {
  const [username, setUsername] = useState<string>();
  const [pageState, setPageState] = useState<string>();
  const [loading, setLoading] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);
      if (typeof username !== 'undefined') {
        const res = await PublicApiFeatures.RequestPasswordReset(username);
        setPageState('If this username exists an email will be sent');
      }
      setLoading(false);
    } catch (error) {
      setPageState('Failed to send email');
      setLoading(false);
    }
  }
  return (
    <div className='resetPassBody'>
      <div className='d-flex align-items-center resetPassNav'>
        <img
          className='h-100'
          src={`${config.REACT_APP_URL}/assets/img/ultra%20teb%20final.svg`}
        />
        <div className='p-2 border-end border-2'>
          <h1 id='NavLogoTitle' className='m-0'>
            ULTRA<strong>TEB</strong>
          </h1>
        </div>
        <h2 className='m-0 p-2'>
          <em>Account Manager</em>
        </h2>
      </div>
      <div className='p-3'>
        <h1>
          Reset Password<i className='fas fa-key m-2'></i>
        </h1>
        <p>
          Please Enter your UltraTeb Account Username. A link will be sent to
          the registered Email account, which can be used to reset your
          password.
        </p>
        <form>
          <div className='row'>
            <div className='col-12 col-lg-4'>
              <h4>Please Enter your Username</h4>
              <input
                className='form-control'
                name='username'
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                type='text'
              />
              <div className='text-end'>
                <button
                  className='btn btn-primary mt-2 prodCardBtn'
                  type='button'
                  onClick={handleSubmit}
                >
                  Send Email
                </button>
                {pageState && <p>{pageState}</p>}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
