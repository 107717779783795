import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { termsValidation } from '../../utils/addProductValidation';
import { FormError } from '../common/FormError';
import { Trans } from 'react-i18next';
interface termsandCondProps {}

export const TermsandCond: React.FC<termsandCondProps> = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const { t } = useTranslation();
  return (
    <>
      <p>
        <Trans i18nKey='addProduct.policy' ns='products'></Trans>
        <Link to='/product-fulfillment' target='_blank'>
          {t('common:clickHere')}
        </Link>
      </p>

      <div className='form-check'>
        <input
          className='form-check-input'
          type='checkbox'
          id='formCheck-1'
          {...register('terms_cond', termsValidation)}
        />
        <label className='form-check-label mb-3' htmlFor='formCheck-1'>
          {t('products:addProduct.term_cond')}{' '}
          <Link to='/terms-conditions' target='_blank'>
            <p>{t('common:term_cond')}</p>
          </Link>
        </label>
        {errors.terms_cond && <FormError name='terms_cond' errors={errors} />}
      </div>
    </>
  );
};
