import APIFeatures from '../axios';
import { newProductRes } from '../responses.interface';
export interface imagesUploadReq {
  form: FormData;
  url: string;
}
class ImageService {
  public createUrlFromFile(files: FileList | null): string[] {
    const strings = [];
    if (files instanceof FileList) {
      for (let i = 0; i < files.length; i++) {
        strings.push(URL.createObjectURL(files[i]));
      }
    }
    return strings;
  }

  public crateFormDataFromFiles(
    files: FileList,
    uploadData: Pick<newProductRes, 'images'>
  ): imagesUploadReq[] {
    const imagesFormData: Array<imagesUploadReq> = [];
    for (let i = 0; i < files.length; i++) {
      const imageFormData = new FormData();
      Object.entries(uploadData.images[i].fields).forEach(([k, v]) => {
        imageFormData.append(k, v);
      });
      imageFormData.append('Content-Type', files[i].type);
      imageFormData.append('file', files[i]);
      imagesFormData.push({
        form: imageFormData,
        url: uploadData.images[i].url,
      });
    }
    return imagesFormData;
  }
}

export const ImageServices = new ImageService();
