import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'bootstrap';
import ReactModal from '../../utils/reactModalProtal';
import { ProductVariants } from '../../utils/models/product';
import { useForm } from 'react-hook-form';
import { ProductVariantsInputs } from '../../utils/types/productVairantInputs';
import {
  descriptionValidation,
  nameValidation,
  priceValidation,
} from '../../utils/addProductValidation';
import { terms_condValidation } from '../../utils/signUpValidation';
import { FormError } from '../common/FormError';
import { useTranslation } from 'react-i18next';
interface itemModalProps {
  setItems: React.Dispatch<React.SetStateAction<ProductVariantsInputs[]>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  item?: ProductVariantsInputs;
  index?: number;
}

export const ItemModal: React.FC<itemModalProps> = ({
  setModal,
  setItems,
  item,
  index,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm<ProductVariantsInputs>();
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalState, setModalState] = useState<Modal>();
  function onSubmit(data: ProductVariantsInputs) {
    if (item && typeof index === 'number')
      setItems((prev) => {
        prev[index] = data;
        return [...prev];
      });
    else
      setItems((prevState) => {
        return [...prevState, data];
      });
    if (modalState) {
      modalState.hide();
    }
    reset();
  }
  function HandleModalSubmit() {
    handleSubmit(onSubmit)();
  }
  useEffect(() => {
    if (modalRef.current !== null) {
      const myModal = new Modal(modalRef.current);
      setModalState(myModal);
      myModal.show();
      modalRef.current.addEventListener('hidden.bs.modal', () => {
        setModal(false);
      });
      if (item) {
        reset(item);
      }
    }
    return () => {
      if (modalRef.current !== null) {
        modalRef.current.removeEventListener('hidden.bs.modal', () => {
          setModal(false);
        });
      }
    };
  }, []);
  const price = watch('price');
  const { t, i18n } = useTranslation();
  return (
    <>
      <ReactModal>
        <div className='modal fade' role='dialog' tabIndex={-1} ref={modalRef}>
          <div
            className='modal-dialog modal-lg modal-dialog-centered'
            role='document'
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h4 className='modal-title'>
                  {t('products:addProduct.ProdList.modal.header')}
                </h4>
                <button
                  type='button'
                  className='btn-close'
                  data-bs-dismiss='modal'
                  aria-label='Close'
                ></button>
              </div>
              <div className='modal-body'>
                <form id='ItemForm'>
                  <div className='row'>
                    <div className='col-sm-12 col-md-6'>
                      <div className='row'>
                        <div className='col'>
                          <label className='col-form-label add-product-label w-100'>
                            {t('products:addProduct.ProdList.modal.itemNameEn')}
                            &nbsp;&nbsp;
                            <input
                              className='form-control w-100'
                              type='text'
                              {...register('name', nameValidation)}
                            />
                          </label>
                          {errors.name && (
                            <FormError errors={errors} name='name' />
                          )}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col'>
                          <label className='col-form-label add-product-label w-100'>
                            {t('products:addProduct.ProdList.modal.itemNameAr')}
                            &nbsp;
                            <input
                              className='form-control w-100'
                              type='text'
                              {...register('arabicName', nameValidation)}
                            />
                          </label>
                          {errors.arabicName && (
                            <FormError errors={errors} name='arabicName' />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='col-sm-12 col-md-6 d-flex'>
                      <div className='add-p-vertical-divider'></div>
                      <div className='d-xxl-flex flex-column justify-content-xxl-center align-items-xxl-start'>
                        <div className='row'>
                          <div className='col'>
                            <h2 className='m-0 fw-bold'>
                              {t('products:addProduct.ProdList.modal.pricing')}
                            </h2>
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <label className='col-form-label d-flex flex-column justify-content-start align-items-start add-product-label'>
                              {t(
                                'products:addProduct.ProdList.modal.productSelling'
                              )}
                              &nbsp;&nbsp;
                              <input
                                className='form-control flex-grow-1'
                                type='number'
                                {...register('price', {
                                  ...priceValidation,
                                  valueAsNumber: true,
                                })}
                              />
                            </label>
                            {errors.price && (
                              <FormError errors={errors} name='price' />
                            )}
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col'>
                            <h4 className='m-0'>
                              {t('products:addProduct.ProdList.modal.resale')}
                            </h4>
                            <h4>
                              {t('products:addProduct.ProdList.modal.income')}{' '}
                              {!price ? 'XX' : price * 0.88} EGP
                            </h4>
                            <p>
                              {t('products:addProduct.ProdList.modal.note')}
                            </p>
                            <div className='row d-flex align-items-start'>
                              <div className='col'>
                                <div className='form-check'>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='formCheck-Item'
                                    {...register(
                                      'terms_cond',
                                      terms_condValidation
                                    )}
                                  />
                                  <label
                                    className='form-check-label mb-3'
                                    htmlFor='formCheck-Item'
                                  >
                                    {t(
                                      'products:addProduct.ProdList.modal.check'
                                    )}
                                  </label>
                                  {errors['terms_cond'] && (
                                    <FormError
                                      errors={errors}
                                      name='terms_cond'
                                    />
                                  )}
                                </div>
                                <div className='text-muted d-flex justify-content-end'>
                                  <button
                                    className='btn btn-secondary add-product-btn m-1 rounded'
                                    type='button'
                                  >
                                    <strong>
                                      {t(
                                        'products:addProduct.ProdList.modal.cancel'
                                      )}
                                    </strong>
                                  </button>
                                  <button
                                    className='btn btn-success add-product-btn m-1 rounded'
                                    type='button'
                                    onClick={HandleModalSubmit}
                                  >
                                    <strong>
                                      {t(
                                        'products:addProduct.ProdList.modal.addProduct'
                                      )}
                                    </strong>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};
