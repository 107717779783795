import { useHistory } from 'react-router-dom';

import React from 'react';
import { OrderAttrs, SalesOrderStatus } from '../../utils/models/orders';
import { useTranslation } from 'react-i18next';

interface OrderTableRowProps {
  order: OrderAttrs;
}

export const OrderTableRow: React.FC<OrderTableRowProps> = ({ order }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  return (
    <tr
      className='orderRow'
      onClick={() => {
        history.push(`/orders/${order._id}`);
      }}
    >
      <td className='py-2 align-middle'>
        <h6 className='m-0'>{order._id}</h6>
      </td>
      <td className='py-2 align-middle'>
        <h6 className='m-0'>
          {/* {new Date(order.createdAt).toDateString()} */}
          {new Date(order.createdAt).toLocaleDateString(
            i18n.language === 'en' ? 'en-US' : 'ar-EG',
            {
              weekday: 'short',
              month: 'short',
              day: '2-digit',
              year: 'numeric',
            }
          )}
        </h6>
      </td>
      <td className='py-2 align-middle'>
        <h6 className='m-0'>
          {i18n.language === 'en'
            ? `${order.totalPrice} EGP`
            : `${order.totalPrice} جنيهًا`}
        </h6>
      </td>
      <td className='table-card-cell text-end py-2 align-middle'>
        <div
          className={`table-status-min ${
            order.status === SalesOrderStatus.PENDING
              ? 'yellow'
              : order.status === SalesOrderStatus.PROCESSING
              ? 'blue'
              : order.status === SalesOrderStatus.DELIVERED
              ? 'green'
              : 'red'
          }`}
        >
          <h6 className='m-0'>{t(`orders:Status.${order.status}`)}</h6>
        </div>
      </td>
      <td className='d-flex justify-content-end table-card-cell text-end py-2 align-middle'>
        <i
          className='material-icons table-row-icon mt-1'
          onClick={() => {
            history.push(`/orders/${order._id}`);
          }}
        >
          info
        </i>
      </td>
    </tr>
  );
};
