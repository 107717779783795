import React from 'react';

interface loginSuccessProps {
  message: string;
}

export const LoginSuccess: React.FC<loginSuccessProps> = ({ message }) => {
  return (
    <div className='row m-0'>
      <div className='col'>
        <div className='d-flex align-items-stretch success-login-box'>
          <div className='d-flex align-items-center success-box-icon'>
            <i className='fas fa-check-circle m-2'></i>
          </div>
          <p className='mb-0 ms-2'>
            Success: {message}
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};
