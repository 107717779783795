import { ProductAttrs } from '../models/product';

import { OrderAttrs } from '../models/orders';
import { InventoryResponse } from '../models/variants';

export function isProducts(data: any[]): data is ProductAttrs[] {
  return 'category' in data[0];
}

export function isOrders(data: any[]): data is OrderAttrs[] {
  return 'status' in data[0];
}

export function isInventory(data: any[]): data is InventoryResponse[] {
  return 'stock' in data[0];
}
