import React from 'react';
import { Control, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Inputs from '../../utils/types/addProductTypes';
interface previewProps {
  coverImage: string;
  control: Control<Inputs>;
}

export const Preview: React.FC<previewProps> = ({ coverImage, control }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const [name, price] = watch(['name', 'price']);
  return (
    <>
      {' '}
      <div className='col'>
        <div className='card shadow card-preview'>
          <img
            className='card-img-top w-100 d-block prod-card-img'
            src={coverImage}
            alt={`${name}-cover`}
          />
          <div className='card-body p-3'>
            <div>
              <p className='prod-card-title'>{name}</p>
            </div>
            <div>
              <p className='prod-card-subtitle mb-1'></p>
            </div>
            <div>
              <div>
                <h2 className='text-start mb-3 prod-card-price'>
                  <strong>
                    {t('products:oneProduct.EGP')} {price}
                  </strong>
                </h2>
              </div>
              <div className='text-center'>
                <button
                  className='btn btn-primary prod-card-btn w-75'
                  type='button'
                  disabled
                >
                  <i className='fa fa-cart-plus'></i>&nbsp;
                  {t('products:addProduct.preview.addToCart')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
