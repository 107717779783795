import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PublicApiFeatures } from '../utils/axios';
import { ClearEmptyStringFromSignUp } from '../utils/helperFunctions';
import { LoginRouterState } from '../utils/LoginAlerts';
import {
  companyName,
  emailValidation,
  firstValidation,
  lastNameValidation,
  passwordValidation,
  phoneValidation,
  terms_condValidation,
  userNameValidation,
} from '../utils/signUpValidation';
import { SignUpInputs } from '../utils/types/signupTypes';
interface SignupProps {}
interface ErrorMessage {
  message: string;
}
export const Signup: React.FC<SignupProps> = () => {
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<SignUpInputs>();
  const { t, i18n } = useTranslation();
  const [backEndErrors, setBackendErrors] = useState<ErrorMessage[]>([]);
  async function changeLanguage() {
    if (i18n.language === 'ar') {
      await i18n.changeLanguage('en');
      i18n.dir();
    } else {
      await i18n.changeLanguage('ar');
      i18n.dir();
    }
  }
  const password = watch('password');
  //Submit Function
  async function submitForm(data: SignUpInputs) {
    try {
      const newData = ClearEmptyStringFromSignUp(data);
      await PublicApiFeatures.SignUp(newData);
      const state: LoginRouterState = {
        type: 'Success',
        reason: 'Signup',
      };
      history.push('/', state);
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setBackendErrors(err.response?.data.errors);
      }
    }
  }
  return (
    <div className='d-flex justify-content-center'>
      <div className='shadow signup-box'>
        <div>
          <div className='row m-0'>
            <div className='col'>
              <div className='d-flex'>
                <img
                  className='d-flex img-login-box'
                  alt='Logo'
                  src='assets/img/ultra%20teb%20final.svg'
                />
                <div>
                  <h1 className='login-title'>
                    ULTRA<strong>TEB</strong>
                  </h1>
                  <h1 className='mt-0 mb-0 sub-login'>
                    <em>Vendor</em>
                  </h1>
                </div>
                <button
                  onClick={changeLanguage}
                  className={`${
                    i18n.language === 'en' ? 'ms-auto' : 'me-auto'
                  } btn btn-primary align-self-center`}
                  style={{ backgroundColor: '#7b6a8e', border: 'none' }}
                >
                  {i18n.language === 'en' ? 'العربيه' : 'English'}
                </button>
              </div>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col d-flex flex-column justify-content-center align-items-center mb-1 mt-1'>
              <h1 className='login-box-title mb-2'>{t('signup:welcomeMsg')}</h1>
              <p className='m-0'>{t('signup:welcomeDescription')}</p>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col d-flex flex-column justify-content-start align-items-start mb-2 mt-2'>
              <h2 className='login-box-title m-0'>
                <strong>
                  <em>{t('signup:header')}</em>
                </strong>
              </h2>
              <p className='m-0'>{t('signup:headerDescription')}</p>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col'>
              <form onSubmit={handleSubmit(submitForm)} id='signupForm'>
                <div className='form-row'>
                  <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 p-1'>
                    <div className='form-row'>
                      <div className='col'>
                        <h4>
                          <em>{t('signup:accountHeader')}</em>
                        </h4>
                        <p>{t('signup:accountDescription')}</p>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.email')}
                            <input
                              className='form-control'
                              type='text'
                              {...register('email', emailValidation)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.email && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>{errors.email.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.username')}
                            <input
                              className='form-control'
                              type='text'
                              {...register('username', userNameValidation)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.username && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>{errors.username.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.password')}
                            <input
                              className='form-control'
                              type='password'
                              {...register('password', passwordValidation)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.password && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>{errors.password.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.confirmPassword')}{' '}
                            <input
                              className='form-control'
                              type='password'
                              {...register('passwordConfirm', {
                                validate: (v) =>
                                  v === password || 'Passwords do not match',
                              })}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.passwordConfirm && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>
                              {errors.passwordConfirm.message}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-12 col-md-6 col-lg-6 col-xl-3 p-1'>
                    <div className='form-row'>
                      <div className='col'>
                        <h4>
                          <em>{t('signup:personalHeader')}</em>
                        </h4>
                        <p>{t('signup:personalDescription')}</p>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.firstName')}{' '}
                            <input
                              className='form-control'
                              type='text'
                              {...register(
                                'vendor.contactPersonFirstName',
                                firstValidation
                              )}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.lastName')}{' '}
                            <input
                              className='form-control'
                              type='text'
                              {...register(
                                'vendor.contactPersonLastName',
                                lastNameValidation
                              )}
                            />
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.phone')}
                            <input
                              className='form-control'
                              type='text'
                              {...register('mobile', phoneValidation)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    {errors.mobile && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>{errors.mobile.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-group d-flex justify-content-start m-0'>
                          <label className='d-flex flex-column flex-grow-1'>
                            {t('signup:fields.address')}{' '}
                            <input
                              className='form-control'
                              type='text'
                              {...register('vendor.address')}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-6 p-1'>
                    <div className='form-row'>
                      <div className='col'>
                        <h4>
                          <em>{t('signup:companyHeader')}</em>
                        </h4>
                        <p>{t('signup:companyDescritpion')}</p>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col-sm-12 col-md-6'>
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start m-0'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.companyName')}{' '}
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.name', companyName)}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        {errors.vendor?.name && (
                          <div className='form-row'>
                            <div className='col'>
                              <div className='form-error-generic'>
                                <p className='p-0 m-0'>
                                  {errors.vendor.name.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start m-0'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.companyPhone')}{' '}
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.phone', phoneValidation)}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        {errors.vendor?.phone && (
                          <div className='form-row'>
                            <div className='col'>
                              <div className='form-error-generic'>
                                <p className='p-0 m-0'>
                                  {errors.vendor.phone.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start m-0'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.companyEmail')}{' '}
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.email', emailValidation)}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        {errors.vendor?.email && (
                          <div className='form-row'>
                            <div className='col'>
                              <div className='form-error-generic'>
                                <p className='p-0 m-0'>
                                  {errors.vendor.email.message}
                                </p>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start m-0'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.website')}{' '}
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.website')}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-12 col-md-6'>
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.noOfEmployees')}{' '}
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.noOfEmployees', {
                                    valueAsNumber: true,
                                  })}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.taxRegistration')} (
                                {t('signup:optional')})
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.taxNumber')}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='col'>
                            <div className='form-group d-flex justify-content-start m-0'>
                              <label className='d-flex flex-column flex-grow-1'>
                                {t('signup:fields.commercialReg')} (
                                {t('signup:optional')})
                                <input
                                  className='form-control'
                                  type='text'
                                  {...register('vendor.commercialReg')}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className='row m-0'>
            <div className='col ms-2'>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  id='formCheck-1'
                  {...register('terms_cond', terms_condValidation)}
                />
                <label className='form-check-label' htmlFor='formCheck-1'>
                  {t('signup:fields.term_cond')}
                </label>
                {errors.terms_cond && (
                  <div className='form-error-generic text-start'>
                    <p className='p-0 m-0'>{errors.terms_cond.message}</p>
                  </div>
                )}
              </div>
              <div className='form-check'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  name='newsletter'
                  id='formCheck-2'
                />
                <label className='form-check-label' htmlFor='formCheck-2'>
                  {t('signup:fields.newsletter')}
                </label>
              </div>
            </div>
          </div>
          {backEndErrors.length !== 0 && (
            <div className='form-error-generic text-left'>
              {backEndErrors.map((el) => {
                return <p className='p-0 m-0'>{el.message}</p>;
              })}
            </div>
          )}
          <div className='row'>
            <div className='col d-flex justify-content-end m-3'>
              <button className='btn btn-secondary me-1' type='button'>
                {t('signup:cancel')}
              </button>
              <button
                className='btn btn-primary login-btn'
                type='submit'
                form='signupForm'
              >
                {t('signup:register')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
