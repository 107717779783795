import React from 'react';
export const HeartSpinnerTable: React.FC = () => {
  return (
    <div className='heart-rate'>
      <svg width='150' height='73' xmlns='http://www.w3.org/2000/svg'>
        <g>
          <title>background</title>
          <rect
            fill='none'
            id='canvas_background'
            height='602'
            width='802'
            y='-1'
            x='-1'
          />
        </g>
        <g>
          <title>Layer 1</title>
          <polyline
            id='svg_1'
            points='0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486'
            strokeMiterlimit='10'
            strokeWidth='3'
            stroke='#8065a0'
            fill='none'
          />
        </g>
      </svg>
      <div className='fade-in'></div>
      <div className='fade-out'></div>
      <h5 className='loading-text'>Loading</h5>
    </div>
  );
};
