import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import Inputs from '../utils/types/addProductTypes';
import { useTranslation, Trans } from 'react-i18next';
import { BasicInfo } from '../components/AddProduct/BasicInfo';
import { Categories } from '../components/AddProduct/categories';
import { Specs } from '../components/AddProduct/specs';
import { Images } from '../components/AddProduct/images';
import { Preview } from '../components/AddProduct/preview';
import { SpecsFormValues } from '../utils/models/specsEntry';
import { TermsandCond } from '../components/AddProduct/termsandCond';
import { LoadingButton } from '../components/common/loadingButton';
import { newProductRes } from '../utils/responses.interface';
import APIFeatures, { S3Upload } from '../utils/axios';
import { ImageServices } from '../utils/services/imagesService';
import { ProdList } from '../components/AddProduct/ProdList';
import { DevTool } from '@hookform/devtools';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';
import { useHistory } from 'react-router';
import axios, { AxiosError } from 'axios';
import config from '../utils/config';
interface addProductProps {}
const DEFUALTSPECS: SpecsFormValues = {
  specs: [
    { spec: 'Size', value: '', default: true },
    { spec: 'Country of Origin', value: '', default: true },
    { spec: 'Brand', value: '', default: true },
  ],
};
export const AddProduct: React.FC<addProductProps> = () => {
  const { t } = useTranslation();
  const [coverImage, setCoverImage] = useState<string>('default');
  const [imageUploadData, setImageUploadData] = useState<newProductRes>();
  const [axiosError, setAxiosError] = useState<AxiosError<any>>();
  const [imageError, setImageError] = useState<string>();
  const methods = useForm<Inputs>({
    mode: 'onChange',
  });
  const history = useHistory();
  useEffect(() => {
    async function getImageData() {
      const res = await APIFeatures.getImageUrls();
      setImageUploadData(res.data);
    }
    getImageData();
  }, []);
  async function handleDataSubmit(data: Inputs) {
    setSubmitting(true);
    if (typeof imageUploadData === 'undefined') throw new Error();
    const formData = ImageServices.crateFormDataFromFiles(
      data.images,
      imageUploadData
    );
    try {
      const upload = await S3Upload.uploadImagesToS3(formData);
      console.log(upload);
      await APIFeatures.postOneProduct(data, upload);
      setSubmitting(false);
      history.push('/products');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.config.baseURL === config.REACT_APP_API) setAxiosError(err);
        else setImageError('Failed to upload Images');
      }
    }
  }

  const [isSubmitting, setSubmitting] = useState(false);
  if (imageUploadData === undefined) return <HeartSpinnerGlobal />;
  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid' id='page-content'>
        <h2 id='page-header'>{t('products:title')}</h2>
        <div className='card shadow' id='table-card'>
          <div className='card-header table-header py-3' id='table-card-header'>
            <h5 className='text-secondary table-card-heading'>
              <strong>{t('products:addProduct.title')}</strong>
              <br />
            </h5>
          </div>
          <div className='card-body' id='table-card-control'>
            <form
              onSubmit={methods.handleSubmit(handleDataSubmit)}
              id='form-Add'
            >
              <div className='row'>
                <FormProvider {...methods}>
                  <div className='col-sm-12 col-md-6'>
                    <div className='row'>
                      <div className='col'>
                        <h4>Product Display Details</h4>
                        <hr className='my-2' />
                      </div>
                    </div>
                    <BasicInfo />
                    <Categories />
                    <Specs />
                    <Images
                      setCoverImage={setCoverImage}
                      uploadData={imageUploadData}
                    />
                  </div>
                  <div className='col-sm-12 col-md-6 d-flex'>
                    <div className='add-p-vertical-divider'></div>
                    <div style={{ width: '90%' }}>
                      <ProdList />
                      <div className='form-row'>
                        <div className='col'>
                          <h1 className='price-section-heading'>
                            {t('products:addProduct.preview.title')}
                          </h1>
                        </div>
                      </div>
                      <div className='form-row d-flex align-items-start'>
                        <Preview
                          coverImage={coverImage}
                          control={methods.control}
                        />
                        <div className='col'>
                          <TermsandCond />
                          <div className='d-flex justify-content-end'>
                            <LoadingButton
                              className={
                                'btn btn-success add-product-btn m-1 rounded'
                              }
                              buttonName={'products:addProduct.button'}
                              state={isSubmitting}
                            />
                          </div>
                        </div>
                      </div>
                      {axiosError !== undefined && (
                        <div className='form-error-generic'>
                          {axiosError.response?.data.errors?.map((el: any) => {
                            return <p className='p-0 m-0'>{el.message}</p>;
                          })}
                        </div>
                      )}
                      {imageError && (
                        <div className='form-error-generic'>
                          <p className='p-0 m-0'>{imageError}</p>;
                        </div>
                      )}
                    </div>
                  </div>
                </FormProvider>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
