export enum LoginMessages {
  SignUpSuccessful = 'Signup Successful. Please confirm your email to login!',
  ConfirmEmailAlert = 'Please confirm your email!',
  EmailConfirmerdSucessfully = 'Email Confirmed Successfully',
  EmailAlreadyConfirmed = 'Email Already Confirmed',
  PasswordChanged = 'Password Changed successfully please login again',
}

export interface LoginRouterState {
  type: 'Error' | 'Success' | 'Timeout';
  reason: 'Signup' | 'Email' | 'REFRESHTOKEN' | 'Password Change';
}
