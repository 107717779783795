import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import APIFeatures from '../../utils/axios';
import Config from '../../utils/config';
import { newProductRes } from '../../utils/responses.interface';
import { ImageServices } from '../../utils/services/imagesService';
interface editImagesProps {
  images: string[];
  slug: string;
  setCoverImage: React.Dispatch<React.SetStateAction<string>>;
  setCurrentImages: React.Dispatch<React.SetStateAction<string[]>>;
  uploadData: Pick<newProductRes, 'images'>;
}

export const EditImages: React.FC<editImagesProps> = ({
  slug,
  images,
  uploadData,
  setCoverImage,
  setCurrentImages,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  function handleUpClick() {
    hiddenFileInput?.current?.click();
  }
  const [newImages, setNewImages] = useState<string[]>();
  const { register, setValue } = useFormContext();
  const [numOfImages, setNumOfImages] = useState(images.length);
  const [disabledButtons, setDisabledButtons] = useState(false);
  const [imagesData, setImagesData] =
    useState<Pick<newProductRes, 'images'>>(uploadData);

  function handleImages(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e.target.files?.length === undefined ||
      e.target.files.length + numOfImages > 4
    )
      return; //image error handling
    const imagesURL = ImageServices.createUrlFromFile(e.target.files);
    setNewImages(imagesURL);
    setValue('images', e.target.files);
  }
  function clearImages() {
    setNewImages(undefined);
    setValue('images', undefined);
  }
  async function handleDeleteImageAPI(e: React.MouseEvent<HTMLButtonElement>) {
    setNumOfImages(numOfImages - 1);
    try {
      setDisabledButtons(true);
      const name = e.currentTarget.name;
      await APIFeatures.deleteOneImage(slug, e.currentTarget.name);
      setCurrentImages((prevState) => {
        return prevState.filter((el) => el !== name);
      });
      setDisabledButtons(false);
    } catch {
      setDisabledButtons(false);
    }
  }
  function handleCoverImage(e: React.ChangeEvent<HTMLInputElement>) {
    setValue('coverImage', e.currentTarget.id);
    setCoverImage(e.target.value);
  }
  return (
    <>
      <div className='form-row'>
        <div className='col d-flex justify-content-between'>
          <p className='add-product-label'>
            {t('products:addProduct.addImage.images')}&nbsp;&nbsp;
          </p>
          <div className='d-flex justify-content-center align-items-center border w-75 image-upload-box rounded text-center p-3'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={handleUpClick}
            >
              {t('products:addProduct.addImage.uplImages')}
              <i
                className='fas fa-file-upload p-2'
                style={{ fontSize: 'larger' }}
              ></i>
            </button>
            <input
              ref={hiddenFileInput}
              type='file'
              className='custom-file-input d-none'
              id='customFile'
              multiple
              accept='image/*'
              onChange={handleImages}
            />
          </div>
        </div>
      </div>
      <div className='form-row'>
        <div className='col d-flex justify-content-between'>
          <p className='add-product-label'>
            {t('products:addProduct.editImages')}&nbsp;&nbsp;
          </p>
          <div className='align-items-center border w-75 image-upload-box rounded'>
            <p className='uploadImgParagraph'>
              {t('products:addProduct.selectCoverImage')}
            </p>
            <div className='image-preview p-2 text-center w-100'>
              <p>Edit Current Images</p>
              {images.map((el, index) => {
                return (
                  <>
                    <input
                      type='radio'
                      name='imgSel'
                      id={el}
                      value={el}
                      onChange={handleCoverImage}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor={el} className='imgSelContainer shadow m-1'>
                      <div className='image-preview-crop shadow'>
                        <img
                          className='image-preview-img'
                          src={`https://${Config.REACT_APP_CLOUDFRONT}/${el}`}
                          alt={`imagePrev-${index}`}
                        />
                      </div>
                      <button
                        onClick={handleDeleteImageAPI}
                        name={el}
                        disabled={disabledButtons}
                        type='button'
                        id={'imgDel' + index}
                      >
                        Delete
                      </button>
                    </label>
                  </>
                );
              })}
              {newImages && <p>New Images</p>}
              {newImages?.map((el, index) => {
                return (
                  <>
                    <input
                      type='radio'
                      name='imgSel'
                      id={imagesData.images[index].key}
                      value={el}
                      onChange={handleCoverImage}
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor={imagesData.images[index].key}
                      className='imgSelContainer shadow m-1'
                    >
                      <div className='image-preview-crop shadow'>
                        <img
                          className='image-preview-img'
                          src={el}
                          alt={`imagePrev-${index}`}
                        />
                      </div>
                    </label>
                  </>
                );
              })}
            </div>
            {newImages && (
              <div className='text-end'>
                <p
                  className='me-2 mb-1'
                  onClick={clearImages}
                  style={{ cursor: 'pointer', color: '#8b0000' }}
                >
                  {t('products:addProduct.addImage.clearImg')}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
