const config = {
  REACT_APP_URL: process.env.REACT_APP_URL
    ? process.env.REACT_APP_URL
    : 'http://localhost:3000',
  REACT_APP_API: process.env.REACT_APP_API
    ? process.env.REACT_APP_API
    : 'http://localhost:7000/api/v1',
  REACT_APP_CLOUDFRONT: process.env.REACT_APP_CLOUDFRONT
    ? process.env.REACT_APP_CLOUDFRONT
    : 'd336xmj1p21te2.cloudfront.net',
} as const;

export default config;
