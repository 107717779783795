import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, Link } from 'react-router-dom';
import APIFeatures from '../../utils/axios';
import { Collapse } from 'bootstrap';
import LocalStorageService, {
  UserInfo,
} from '../../utils/services/localStorageService';
import Logo from '../../assets/img/ultra teb final.svg';
import logoWhite from '../../assets/img/ultra teb final white.svg';
import user from '../../assets/img/User.svg';
import { useAuth } from '../../utils/authContext';

export const Navigation: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { Logout } = useAuth();
  const navBar = useRef<HTMLDivElement>(null);

  const { AuthState } = useAuth();
  async function ChangeLanguage() {
    if (i18n.language === 'en') {
      await i18n.changeLanguage('ar');
      i18n.dir();
    } else {
      await i18n.changeLanguage('en');
      i18n.dir();
    }
  }

  async function LogoutHandler() {
    await APIFeatures.Logout();
    Logout();
    history.push('/');
  }
  return (
    <>
      <nav
        className='navbar navbar-dark fixed-top bg-white shadow off-canvas'
        data-right-drawer='0'
        data-open-drawer='0'
      >
        <div className='container-fluid flex-column'>
          <button
            className='btn btn-dark d-flex justify-content-center align-items-center drawer-knob'
            type='button'
            data-open='drawer'
          >
            <i className='fas fa-angle-double-right'></i>
          </button>
          <div
            className='shadow d-flex flex-column align-items-center p-3'
            id='sidebar-nav-wrapper-mob'
          >
            <div
              className='text-center d-flex justify-content-start'
              id='logo-nav-mob'
            >
              <img id='logo-mob' src={Logo} alt='Logo-mobile' />
              <div
                className='d-flex flex-column justify-content-center align-items-start'
                style={{ marginTop: '1rem' }}
              >
                <h4 id='logo-title-mob' className='m-0'>
                  ULTRA<strong>TEB</strong>
                </h4>
                <h4 id='logo-subtitle-mob' className='m-0'>
                  <em>Vendor</em>
                </h4>
              </div>
              <div className='d-flex flex-column justify-content-start brand-line'>
                <button
                  className='btn'
                  type='button'
                  data-dismiss='drawer'
                  style={{
                    fontSize: '1.5rem',
                    color: '#685083',
                    opacity: '0.5',
                  }}
                >
                  <i className='fas fa-angle-double-left'></i>
                </button>
              </div>
            </div>
            <ul className='nav navbar-nav' id='sidebar-nav-mob'>
              <li className='nav-item d-flex w-100 flex-column'>
                <NavLink
                  to='/'
                  exact
                  activeClassName='active disabled'
                  className='nav-link d-flex align-items-center side-nav-link'
                  id='dash-mob'
                >
                  <i className='material-icons nav-icon'>dashboard</i>
                  <p className='m-0'>{t('navigation:side.dashboard')}</p>
                </NavLink>
              </li>
              <li className='nav-item d-flex w-100 flex-column'>
                <NavLink
                  to='/products'
                  activeClassName='active disabled'
                  className='nav-link d-flex align-items-center side-nav-link'
                  id='products-mob'
                >
                  <i className='material-icons nav-icon'>shopping_cart</i>
                  <p className='m-0'>{t('navigation:side.products')}</p>
                </NavLink>
              </li>
              <li className='nav-item d-flex w-100 flex-column'>
                <NavLink
                  to='/orders/'
                  activeClassName='active disabled'
                  className='nav-link d-flex align-items-center side-nav-link'
                  id='orders-mob'
                >
                  <i
                    className='fas fa-box nav-icon'
                    style={{ padding: '3px' }}
                  ></i>
                  <p className='m-0'>{t('navigation:side.orders')}</p>
                </NavLink>
              </li>
              <li className='nav-item d-flex w-100 flex-column'>
                <NavLink
                  className='nav-link d-flex align-items-center side-nav-link'
                  activeClassName='active disabled'
                  to='/inventory'
                  id='inventory-mob'
                >
                  <i
                    className='fas fa-clipboard-list nav-icon'
                    style={{ paddingRight: '5px', paddingLeft: '5px' }}
                  ></i>
                  <p className='m-0'>{t('navigation:side.inventory')}</p>
                </NavLink>
              </li>
              <li className='nav-item d-flex w-100 flex-column'>
                <NavLink
                  activeClassName='active disabled'
                  className='nav-link d-flex align-items-center side-nav-link'
                  to='/analytics'
                  id='analytics-mob'
                >
                  <i className='material-icons nav-icon'>insert_chart</i>
                  <p className='m-0'>{t('navigation:side.analytics')}</p>
                </NavLink>
              </li>
              <li className='nav-item d-flex w-100 flex-column'>
                <NavLink
                  activeClassName='active disabled'
                  className='nav-link d-flex align-items-center side-nav-link'
                  to='/billing'
                >
                  <i className='material-icons nav-icon'>credit_card</i>
                  <p className='m-0'>{t('navigation:side.billing')}</p>
                </NavLink>
              </li>
            </ul>
            <div
              className='d-flex flex-column justify-content-end align-items-center align-content-center'
              id='profile-nav-wrapper-mob'
            >
              <hr
                className='w-100'
                style={{ opacity: '0.7', marginBottom: '0.3rem' }}
              />
              <div className='d-flex flex-row'>
                <div className='d-flex flex-row justify-content-center align-items-center'>
                  <img
                    id='profile-img-mob'
                    src={user}
                    style={{ cursor: 'pointer' }}
                    alt='profile-img-mob'
                    onClick={() => {
                      history.push('/profile');
                    }}
                  />
                  <h6
                    id='profile-name-mob'
                    onClick={() => {
                      history.push('/profile');
                    }}
                  >
                    {`${AuthState.firstName} ${AuthState.lastName}`}
                  </h6>
                </div>
                <div
                  className='d-flex flex-column flex-grow-0 justify-content-center'
                  id='profile-ctrls-mob'
                >
                  <button
                    style={{ border: 'none', background: 'none' }}
                    onClick={LogoutHandler}
                  >
                    <i className='fas fa-sign-in-alt profile-ctrl-btn'></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <section className='d-none d-md-block'>
        <div
          className='shadow d-flex flex-column align-items-center'
          id='sidebar-nav-wrapper'
        >
          <div className='text-center' id='logo-nav'>
            <img id='logo' alt='logo' src={Logo} />
            <h2 id='logo-title' className='m-0'>
              ULTRA<strong>TEB</strong>
            </h2>
            <h2 id='logo-subtitle' className='m-0'>
              <em>Vendor</em>
            </h2>
          </div>
          <ul className='nav nav-pills' id='sidebar-nav'>
            <li className='nav-item d-flex w-100 flex-column'>
              <NavLink
                exact
                id='dash'
                to='/'
                activeClassName='active disabled'
                className='nav-link d-flex align-items-center w-100 side-nav-link'
              >
                <i className='material-icons nav-icon'>dashboard</i>
                <p className='m-0'>{t('navigation:side.dashboard')}</p>
              </NavLink>
            </li>
            <li className='nav-item d-flex w-100 flex-column'>
              <NavLink
                id='products'
                activeClassName='active disabled'
                to='/products'
                className='nav-link d-flex align-items-center side-nav-link'
              >
                <i className='material-icons nav-icon'>shopping_cart</i>
                <p className='m-0'>{t('navigation:side.products')}</p>
              </NavLink>
            </li>
            <li className='nav-item d-flex w-100 flex-column'>
              <NavLink
                exact
                to='/orders'
                activeClassName='active disabled'
                className='nav-link d-flex align-items-center side-nav-link'
                id='orders-mob'
              >
                <i
                  className='fas fa-box nav-icon'
                  style={{ padding: '3px' }}
                ></i>
                <p className='m-0'>{t('navigation:side.orders')}</p>
              </NavLink>
            </li>
            <li className='nav-item d-flex w-100 flex-column'>
              <NavLink
                to='/inventory'
                activeClassName='active disabled'
                className='nav-link d-flex align-items-center side-nav-link'
              >
                <i
                  className='fas fa-clipboard-list nav-icon'
                  style={{ paddingRight: '5px', paddingLeft: '5px' }}
                ></i>
                <p className='m-0'>{t('navigation:side.inventory')}</p>
              </NavLink>
            </li>
            <li className='nav-item d-flex w-100 flex-column'>
              <NavLink
                to='/analytics'
                activeClassName='active disabled'
                className='nav-link d-flex align-items-center side-nav-link'
              >
                <i className='material-icons nav-icon'>insert_chart</i>
                <p className='m-0'>{t('navigation:side.analytics')}</p>
              </NavLink>
            </li>
            <li className='nav-item d-flex w-100 flex-column'>
              <NavLink
                to='/billing'
                activeClassName='active disabled'
                className='nav-link d-flex align-items-center side-nav-link'
              >
                <i className='material-icons nav-icon'>credit_card</i>
                <p className='m-0'>{t('navigation:side.billing')}</p>
              </NavLink>
            </li>
          </ul>
          <div
            className='d-flex flex-column justify-content-center align-items-center align-content-center'
            id='profile-nav-wrapper'
          >
            <hr
              className='w-100'
              style={{ opacity: 0.2, marginBottom: '0.5rem' }}
            />
            <div className='d-flex align-items-center'>
              <img
                onClick={() => {
                  history.push('/profile');
                }}
                id='profile-img'
                alt='profile'
                style={{ cursor: 'pointer' }}
                src={user}
              />
              <h6
                onClick={() => {
                  history.push('/profile');
                }}
                id='profile-name'
              >
                {`${AuthState.firstName} ${AuthState.lastName}`}
              </h6>
              <div
                className='d-inline-flex flex-grow-0 justify-content-center'
                id='profile-ctrls'
              >
                <button
                  style={{ background: 'none', border: 'none' }}
                  onClick={LogoutHandler}
                >
                  <i className='fas fa-sign-in-alt profile-ctrl-btn'></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <nav className='navbar navbar-dark navbar-expand-md navigation-clean'>
        <div className='container-fluid'>
          <div className='d-sm-block d-md-none d-lg-none'>
            <img className='top-bar-logo' src={logoWhite} alt={`logo-white`} />
          </div>
          <Link className='navbar-brand' to='/profile'>
            {AuthState.companyName}
          </Link>
          <button
            className='navbar-toggler'
            onClick={() => {
              if (navBar.current !== null)
                new Collapse(navBar.current).toggle();
            }}
          >
            <span className='sr-only'>Toggle navigation</span>
            <i className='fa fa-ellipsis-h'></i>
          </button>
          <div className='collapse navbar-collapse' id='navcol-1' ref={navBar}>
            <ul
              className={`nav navbar-nav ${
                i18n.language === 'en' ? 'ms-auto' : 'me-auto'
              } text-end`}
            >
              <li className='nav-item dropdown'>
                <a
                  className='dropdown-toggle nav-link'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  href='#'
                >
                  <i className='fa fa-bell'></i>
                  <strong>
                    &nbsp;{t('navigation:top.notifications')}&nbsp;
                  </strong>
                </a>
                <div className='dropdown-menu'></div>
              </li>
              <li className='nav-item'></li>
              <li className='nav-item'>
                <a className='nav-link' href='#'>
                  <i className='fa fa-user'></i>
                  <strong>&nbsp;{t('navigation:top.contactUs')}</strong>&nbsp;
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#' onClick={ChangeLanguage}>
                  <i className='fas fa-globe-americas'></i>
                  <strong>
                    &nbsp;{i18n.language === 'en' ? 'العربيه' : 'English'}&nbsp;
                  </strong>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
