export const emailValidation = {
  required: 'Email is required',
};
export const firstValidation = {
  required: 'First name is required',
};
export const lastNameValidation = {
  required: 'Last name is required',
};
export const userNameValidation = {
  required: 'Username is required',
  minLength: {
    value: 6,
    message: 'Username cannot be less than 6 characters',
  },
  maxLength: {
    value: 30,
    message: 'Username cannot be more than 30 characters',
  },
  pattern: {
    value: /^[a-z0-9_-]+$/,
    message: 'Username cannot contain uppercase letters',
  },
};

export const passwordValidation = {
  required: 'Password is required',
  minLength: {
    value: 8,
    message: 'Password cannot be less than 8 characters',
  },
  maxLength: {
    value: 64,
    message: 'Password cannot be more than 64 characters',
  },
};
export const phoneValidation = {
  required: 'Phone is required',
  minLength: {
    value: 11,
    message: 'Phone number should be 11 numbers',
  },
  maxLength: {
    value: 11,
    message: 'Phone number should be 11 numbers',
  },
};
export const companyName = {
  required: 'Company Name is required',
};
export const companyPhone = {
  required: 'Company Phone is required',
  minLength: 10,
  maxLength: 11,
};
export const companyEmail = {
  required: 'Company Email is required',
};
export const terms_condValidation = {
  required: 'Please accept terms and conditons',
};
