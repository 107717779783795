import React, { Suspense, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { AddProduct } from "./pages/addProduct";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/Spinners/spinners.css";
//FONTS
import "./assets/fonts/fontawesome5-overrides.min.css";
import "./assets/fonts/material-icons.min.css";
import "./assets/fonts/fontawesome-all.min.css";
import "./assets/fonts/font-awesome.min.css";

import { Login } from "./pages/login";
import { Products } from "./pages/products";
import { Navigation } from "./components/common/navigation";
import { useAuth } from "./utils/authContext";
import Dashboard from "./pages/dashboard";
import { Inventory } from "./pages/inventory";
import HeartSpinnerGlobal from "./components/common/globalSpinnner";
import { Orders } from "./pages/orders";
import { SingleOrder } from "./pages/SingleOrder";
import { Signup } from "./pages/signup";
import { Profile } from "./pages/profile";
import { EmailConfirmation } from "./pages/emailConfirm";
import { ForgetPassword } from "./pages/forgetPassword";
import { ResetPassword } from "./pages/resetPassword";
import { InventroyReplinsh } from "./pages/inventroyReplinsh";
import { EditProduct } from "./pages/editProduct";
function App() {
  const { AuthState } = useAuth();
  return (
    <Suspense fallback={<HeartSpinnerGlobal />}>
      {AuthState.isLoggedIn && <Navigation />}
      <Switch>
        <Route path="/confirm_email/:token" component={EmailConfirmation} />
        <Route path="/forget-password" component={ForgetPassword} />
        <Route path="/resetPassword/:token" component={ResetPassword} />
        {!AuthState.isLoggedIn && (
          <>
            <Route path="/" exact component={Login} />
            <Route path="/signup" component={Signup} />
          </>
        )}
        {AuthState.isLoggedIn && (
          <>
            <Route path="/" exact component={Dashboard} />
            <Route path="/products" exact component={Products} />
            <Route path="/products/add-product" component={AddProduct} />
            <Route
              path="/products/edit-product/:productSlug"
              component={EditProduct}
            />
            <Route path="/inventory" exact component={Inventory} />
            <Route
              path="/inventory/:slug/variants/:variantId"
              component={InventroyReplinsh}
            />
            <Route exact path="/orders" component={Orders} />
            <Route path="/orders/:orderId" component={SingleOrder} />
            <Route path="/profile" component={Profile} />
          </>
        )}
      </Switch>
    </Suspense>
  );
}

export default App;
