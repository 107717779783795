import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { StyleSheetHandle } from './services/styleSheetService';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';

interface TranslationsWrapperProps {}

export const TranslationsWrapper: React.FC<TranslationsWrapperProps> = (
  props
) => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>();
  const { i18n } = useTranslation();
  useEffect(() => {
    async function changeStyles() {
      try {
        setLoading(true);
        if (i18n.language === 'ar')
          await StyleSheetHandle.changeStyleSheets('ar');
        else await StyleSheetHandle.changeStyleSheets('en');
        setLoading(false);
        i18n.dir();
      } catch {
        setLoading(false);
        setError('Failed to change language');
      }
    }
    changeStyles();
  }, [i18n.language]);
  return (
    <React.Fragment>
      {loading ? (
        <HeartSpinnerGlobal />
      ) : error ? (
        <h1>{error}</h1>
      ) : (
        props.children
      )}
    </React.Fragment>
  );
};
