import React, { useState } from 'react';
import { PublicApiFeatures, PasswordData } from '../utils/axios';
import { useHistory, RouteComponentProps } from 'react-router-dom';

import axios from 'axios';
import { LoginRouterState } from '../utils/LoginAlerts';
import config from '../utils/config';
interface resetPasswordProps {}
interface MatchParams {
  token: string;
}
interface resetPasswordProps extends RouteComponentProps<MatchParams> {}

export const ResetPassword: React.FC<resetPasswordProps> = (props) => {
  const [passwordData, setPasswordData] = useState<PasswordData>({
    password: '',
    passwordConfirm: '',
  });
  const history = useHistory();
  const [error, setError] = useState<string>();
  async function handleSubmit() {
    try {
      if (passwordData.password === '' || passwordData.passwordConfirm === '')
        throw new Error('Password and Password confirm must not be empty');
      if (passwordData.password !== passwordData.passwordConfirm)
        throw new Error('Passwords must match');
      await PublicApiFeatures.ResetPassword(
        passwordData,
        props.match.params.token
      );
      const state: LoginRouterState = {
        reason: 'Password Change',
        type: 'Success',
      };
      history.push('/', state);
    } catch (err) {
      if (axios.isAxiosError(err)) setError(err.response?.data.message);
      if (err instanceof Error) setError(err.message);
    }
  }
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setPasswordData((prevState) => {
      return {
        ...prevState,
        [e.target.name]: e.target.value,
      };
    });
  }
  return (
    <div className='resetPassBody'>
      <div className='d-flex align-items-center resetPassNav'>
        <img
          className='h-100'
          src={`${config.REACT_APP_URL}/assets/img/ultra%20teb%20final.svg`}
        />
        <div className='p-2 border-end border-2'>
          <h1 id='NavLogoTitle' className='m-0'>
            ULTRA<strong>TEB</strong>
          </h1>
        </div>
        <h2 className='m-0 p-2'>
          <em>Account Manager</em>
        </h2>
      </div>
      <div className='p-3'>
        <h1>
          Reset Password<i className='fas fa-key m-2'></i>
        </h1>
        <p>
          Enter your new password and confirm it. This password will be used as
          your new password from now on.
        </p>
        <form>
          <div className='row'>
            <div className='col-12 col-lg-4'>
              <h4>Please Enter your new password</h4>
              <input
                type='password'
                name='password'
                className='form-control'
                placeholder='Password'
                value={passwordData.password}
                onChange={handleChange}
              />
              <h4 className='mt-2'>Please Confirm your password</h4>
              <input
                type='password'
                name='passwordConfirm'
                className='form-control'
                placeholder='Confirm Password'
                value={passwordData.passwordConfirm}
                onChange={handleChange}
              />
              <div className='text-end'>
                <button
                  className='btn btn-dark mt-2'
                  type='button'
                  onClick={handleSubmit}
                >
                  Change Password
                </button>

                {error && error}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
