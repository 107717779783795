import axios from 'axios';
import { data } from 'jquery';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';
import { GlobalError } from '../components/common/globalError';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';
import APIFeatures from '../utils/axios';
import { getVariantFromProduct } from '../utils/helperFunctions';
import { ItemTypeAttrs } from '../utils/models/itemTypes';
import { ProductAttrs, ProductVariants } from '../utils/models/product';
import { InventoryResponse } from '../utils/models/variants';

interface MatchParams {
  variantId: string;
  slug: string;
}
interface inventroyReplinshProps extends RouteComponentProps<MatchParams> {}

enum ErrorReason {
  AXIOS = 'AXIOS',
  TERMS = 'TERMS',
  STOCK = 'STOCK',
}

export const InventroyReplinsh: React.FC<inventroyReplinshProps> = (props) => {
  const [variant, setVariant] = useState<InventoryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<boolean>(false);
  const [dataError, setDataError] = useState<{
    reason: ErrorReason;
    message: string;
  }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [checkBoxes, setCheckBoxes] = useState({
    hasUnlimitedStock: false,
    terms: false,
  });
  const [stock, setStock] = useState<number>(0);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  async function submitChanges() {
    try {
      if (
        variant?.product.MOQ !== undefined &&
        checkBoxes.hasUnlimitedStock === false &&
        stock < variant.product.MOQ &&
        stock !== 0
      )
        throw new Error('Stock must be higher than MOQ');
      if (!checkBoxes.terms)
        throw new Error('Please check terms and conditions');

      setIsSubmitting(true);
      await APIFeatures.patchOneVariant(
        {
          stock: checkBoxes.hasUnlimitedStock ? undefined : stock,
          hasUnlimitedStock: checkBoxes.hasUnlimitedStock,
        },
        props.match.params.variantId,
        props.match.params.slug
      );
      setIsSubmitting(false);
      history.push('/inventory');
    } catch (err) {
      if (axios.isAxiosError(err)) {
        setDataError({
          reason: ErrorReason.AXIOS,
          message: err.response?.data.message,
        });
      }
      if (err instanceof Error) {
        if (err.message === 'Please check terms and conditions')
          setDataError({
            reason: ErrorReason.TERMS,
            message: err.message,
          });
        if (err.message === 'Stock must be higher than MOQ')
          setDataError({
            reason: ErrorReason.STOCK,
            message: err.message,
          });
      }
    }
  }
  useEffect(() => {
    async function getProduct() {
      try {
        setLoading(true);
        const variantResponse = await APIFeatures.getOneVariant(
          props.match.params.slug,
          props.match.params.variantId
        );
        setVariant(variantResponse.data);
        setStock(variantResponse.data.stock);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (axios.isAxiosError(err)) {
          setError(true);
        }
      }
    }
    getProduct();
  }, []);
  if (!error && loading) return <HeartSpinnerGlobal />;
  if (error && !loading) return <GlobalError />;
  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid' id='page-content'>
        <h2 id='page-header'>{t('inventoryReplenish:header')}&nbsp;</h2>
        <div className='card shadow' id='table-card'>
          <div className='card-header table-header py-3' id='table-card-header'>
            <h5 className='text-secondary table-card-heading'>
              <strong>{t('inventoryReplenish:subheader')}</strong>
              <br />
            </h5>
          </div>
          <div
            className='card-body'
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            <h3>{t('inventoryReplenish:product.header')}</h3>
            <div className='d-flex flex-wrap'>
              <div className='m-2'>
                <img
                  className='ImgInventoryRep'
                  src={`https://${process.env.REACT_APP_CLOUDFRONT}/${variant?.product.coverImage}`}
                />
              </div>
              <div className='d-flex flex-column inventoryProdInfo'>
                <h6>
                  <strong>{t('inventoryReplenish:product.name')}&nbsp;</strong>
                  {i18n.language === 'ar'
                    ? `${variant?.product.arabicName} - ${variant?.arabicName}`
                    : `${variant?.product.name} - ${variant?.name}`}
                  <br />
                </h6>
                {/*<h6>
                  <strong>{t('inventoryReplenish:product.itemType')}</strong>
                  &nbsp;
                  {i18n.language === 'ar'
                    ? (variant?.product.itemType as ItemTypeAttrs).arabicName
                    : (variant?.product.itemType as ItemTypeAttrs).name}
                </h6>*/}
                <h6>
                  <strong>{t('inventoryReplenish:product.sku')}&nbsp;</strong>#
                  {variant?.product._id}
                  <br />
                </h6>
                <h6>
                  <strong>
                    {t('inventoryReplenish:product.vendorId')}&nbsp;
                  </strong>
                  #{variant?.product.vendorId}
                  <br />
                </h6>
                <h6>
                  <strong>{t('inventoryReplenish:product.price')} </strong>
                  {variant?.price} EGP
                  <br />
                </h6>
                <h6>
                  <strong>{t('inventoryReplenish:product.MOQ')}: </strong>
                  {variant?.product.MOQ}
                  <br />
                </h6>
              </div>
            </div>
          </div>
          <hr />
          <div
            className='card-body'
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            <h2>{t('inventoryReplenish:details.header')}</h2>
            <div className='d-flex justify-content-around mt-3'>
              <div className='text-center'>
                <h3>{t('inventoryReplenish:details.stock')}</h3>
                <h1 className='text-success'>
                  {variant?.hasUnlimitedStock ? (
                    <i className='fas fa-infinity'></i>
                  ) : (
                    variant?.stock
                  )}
                </h1>
              </div>
              <div className='text-center'>
                <h3>{t('inventoryReplenish:details.onHold')}</h3>
                <h1 className='text-primary'>{variant?.stockOnHold}</h1>
              </div>
              <div className='text-center'>
                <h3>{t('inventoryReplenish:details.min')}</h3>
                <h1 className='text-danger'>{variant?.product.MOQ}</h1>
              </div>
            </div>
          </div>
          <hr />
          <div
            className='card-body'
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            <h3>{t('inventoryReplenish:update.header')}</h3>
            <hr />
            <form>
              <div className='row'>
                <div className='col-12 col-lg-8'>
                  <h4>{t('inventoryReplenish:update.notes.header')}</h4>
                  <p className='m-0'>
                    {t('inventoryReplenish:update.notes.line1')}
                  </p>
                  <p className='m-0'>
                    {t('inventoryReplenish:update.notes.line2')}
                  </p>
                  <p className='m-0'>
                    {t('inventoryReplenish:update.notes.line3')}
                    <strong>
                      {t('inventoryReplenish:update.notes.strong')}
                    </strong>
                  </p>
                  <p className='m-0'>
                    {t('inventoryReplenish:update.notes.line4')}
                    <strong>
                      {t('inventoryReplenish:update.notes.strong2')}
                    </strong>
                  </p>
                  <p className='m-0'>
                    {t('inventoryReplenish:update.notes.line5')}
                  </p>
                </div>
                <div className='col-12 col-lg-4 d-flex flex-column justify-content-center border-start p-3'>
                  <label className='form-label'>
                    <strong>{t('inventoryReplenish:update.newVal')}</strong>
                    <input
                      className='form-control'
                      type='number'
                      disabled={checkBoxes.hasUnlimitedStock}
                      value={stock}
                      min={variant?.product.MOQ}
                      onChange={(e) => {
                        setStock(Number(e.currentTarget.value));
                      }}
                    />
                    {dataError?.reason === 'STOCK' && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>{dataError.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </label>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='formCheck-1'
                      value={String(checkBoxes.hasUnlimitedStock)}
                      checked={checkBoxes.hasUnlimitedStock}
                      onChange={(e) => {
                        setCheckBoxes({
                          ...checkBoxes,
                          hasUnlimitedStock: e.target.checked,
                        });
                      }}
                    />
                    <label className='form-check-label' htmlFor='formCheck-1'>
                      <strong>
                        {t('inventoryReplenish:update.unlimited')}
                      </strong>
                    </label>
                  </div>
                  <div className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='formCheck-2'
                      value={String(checkBoxes.terms)}
                      onChange={(e) => {
                        setCheckBoxes({
                          ...checkBoxes,
                          terms: e.target.checked,
                        });
                      }}
                    />
                    <label className='form-check-label' htmlFor='formCheck-2'>
                      {t('inventoryReplenish:update.terms')}
                    </label>
                    {dataError?.reason === 'TERMS' && (
                      <div className='form-row'>
                        <div className='col'>
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>{dataError.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  <button
                    className='btn btn-success mt-2'
                    type='button'
                    onClick={submitChanges}
                  >
                    {isSubmitting ? (
                      <span
                        className='spinner-border spinner-border-sm'
                        role='status'
                        aria-hidden='true'
                      ></span>
                    ) : (
                      <i className='fas fa-sync'></i>
                    )}
                    {isSubmitting ? 'Loading' : ' Update Stock'}
                  </button>
                  {dataError?.reason === 'AXIOS' && (
                    <div className='form-row'>
                      <div className='col'>
                        <div className='form-error-generic'>
                          <p className='p-0 m-0'>{dataError.message}</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
            <div className='d-flex justify-content-around'>
              {error ? error : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
