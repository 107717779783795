import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductAttrs } from '../../utils/models/product';
import { useHistory } from 'react-router-dom';
import { ProductVariants } from '../../utils/models/product';
import { InventoryResponse } from '../../utils/models/variants';
interface inventoryTableRowProps {
  inventoryItem: InventoryResponse;
}

export const InventoryTableRow: React.FC<inventoryTableRowProps> = ({
  inventoryItem,
}) => {
  const { product } = inventoryItem;
  const id = inventoryItem._id;
  const history = useHistory();
  const { t, i18n } = useTranslation();
  function PushHistory() {
    history.push(`/inventory/${product.slug}/variants/${id}`);
  }
  return (
    <tr>
      <td className='d-flex table-card-cell'>
        <div>
          <img
            className='border rounded-0 table-thumb'
            src={`https://${process.env.REACT_APP_CLOUDFRONT}/${product.coverImage}`}
            alt={`${product.name}-coverimage`}
          />
        </div>
        <div className='d-flex flex-column product-description'>
          <p className='product-table-name m-0'>
            {i18n.language === 'ar'
              ? `${product.arabicName} ${inventoryItem.name}`
              : `${product.name} ${inventoryItem.name}`}
          </p>
          <p className='product-table-id m-0'>
            {t('products:oneProduct.SKU')} {product._id}
          </p>
          <p className='product-table-id m-0'>
            {t('products:oneProduct.vendorId')} {product.vendorSku}
          </p>
        </div>
      </td>
      <td className='flex-row table-card-cell'>
        <div className='d-flex justify-content-around inventory-detail'>
          <div className='inventory-detail-item'>
            <h5 className='text-center m-0'>
              {t('inventory:products.oneProduct.InStock')}
            </h5>
            <h1 className='text-center m-0 text-success'>
              {inventoryItem.hasUnlimitedStock ? (
                <i className='fas fa-infinity'></i>
              ) : (
                inventoryItem.stock
              )}
            </h1>
          </div>
          <div className='inventory-detail-item'>
            <h5 className='text-center m-0'>
              {t('inventory:products.oneProduct.onHold')}
            </h5>
            <h1 className='text-center m-0 text-primary'>
              {inventoryItem.stockOnHold}
            </h1>
          </div>
          <div className='inventory-detail-item'>
            <h5 className='text-center m-0'>
              {t('inventory:products.oneProduct.minimum')}
            </h5>
            <h1 className='text-center m-0b text-danger'>{product.MOQ}</h1>
          </div>
        </div>
      </td>
      <td className='table-card-cell'>
        <div className='d-flex justify-content-start align-items-center'>
          <div className='table-status green'>
            <h6 className='m-0'>OK</h6>
          </div>
        </div>
      </td>
      <td className='text-center justify-content-center'>
        <h3>
          <i
            className='fas fa-sync table-row-icon replenish'
            onClick={PushHistory}
          ></i>
        </h3>
      </td>
    </tr>
  );
};
