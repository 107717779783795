import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';
import APIFeatures from '../utils/axios';
import { FullOrderAttrs, ItemsAttrs } from '../utils/models/orders';
import { ProductVariants } from '../utils/models/product';
interface MatchParams {
  orderId: string;
}
interface SingleOrderProps extends RouteComponentProps<MatchParams> {}

export const SingleOrder: React.FC<SingleOrderProps> = (props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [order, setOrder] = useState<FullOrderAttrs>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getOrder() {
      const res = await APIFeatures.getOneOrder(props.match.params.orderId);
      setOrder(res.data);
      setLoading(false);
    }
    getOrder();
  }, []);
  if (order === undefined && loading) return <HeartSpinnerGlobal />;
  if (order === undefined) return <h1>Error</h1>;
  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid' id='page-content'>
        <div className='d-flex justify-content-between m-3'>
          <h2 className='mb-0' style={{ color: '#302363' }}>
            <i className='fas fa-box mx-2'></i>
            {t('orders:singleOrder.singleOrder')}
            <br />
          </h2>
          <button
            className='btn btn-secondary ms-2'
            type='button'
            onClick={() => {
              history.push('/orders');
            }}
          >
            <i className='far fa-caret-square-left mx-1'></i>
            {t('orders:singleOrder.backToOrders')}
          </button>
        </div>

        <hr />
        <div className='card'>
          <div className='card-header'>
            <h3>
              {t('orders:orderID')}:&nbsp;
              <strong>{order._id}</strong>
              <br />
            </h3>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-10 d-flex align-items-center'>
                <div>
                  <h5>
                    <strong>{t('orders:singleOrder.orderDate')}: </strong>
                    {new Date(order.createdAt).toLocaleDateString(
                      i18n.language === 'en' ? 'en-US' : 'ar-EG',
                      {
                        weekday: 'short',
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric',
                      }
                    )}
                  </h5>
                  <h5>
                    <strong>{t('orders:singleOrder.deliveredAt')}: </strong>
                    {order.deliveredAt
                      ? new Date(order.deliveredAt).toLocaleDateString(
                          i18n.language === 'en' ? 'en-US' : 'ar-EG',
                          {
                            weekday: 'short',
                            month: 'short',
                            day: '2-digit',
                            year: 'numeric',
                          }
                        )
                      : '-'}{' '}
                  </h5>
                </div>
              </div>
              <div className='col-2 d-flex flex-column'>
                <div
                  className={`OrderStatus ${
                    order.status === 'PROCESSING' ? 'Blue' : 'Green'
                  } mb-2`}
                >
                  <h6 className='mb-0'>{t(`orders:Status.${order.status}`)}</h6>
                </div>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col'>
                <div>
                  <h2>{t('orders:singleOrder.products')}</h2>
                  <h5 className='my-2'>
                    {t('orders:singleOrder.noOfProducts')}: {order.items.length}
                  </h5>
                  <div
                    style={{
                      overflowY: 'auto',
                      // height: "370rem",
                    }}
                  >
                    {order.items.map((el) => {
                      return (
                        <div className='card m-3' key={el.productId}>
                          <div className='card-body shadow'>
                            <div className='d-flex'>
                              <div>
                                <h3>
                                  {i18n.language === 'en'
                                    ? el.product.name
                                    : el.product.arabicName}
                                  <br />
                                </h3>
                                <h5 className='text-muted mb-2'>
                                  {el.productId}
                                  <br />
                                </h5>
                              </div>
                            </div>
                            <div className='table-responsive'>
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th className='col-lg-5'>
                                      {t('orders:singleOrder.variantName')}
                                    </th>
                                    <th className='col-lg-4'>
                                      {t('orders:singleOrder.quantity')}
                                    </th>
                                    <th className='col-lg-3'>
                                      {t('orders:singleOrder.price')}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {el.variants.map((item) => {
                                    return (
                                      <tr key={item.variantId}>
                                        <td>
                                          {i18n.language === 'en'
                                            ? item.productVariant.name
                                            : item.productVariant.arabicName}
                                        </td>
                                        <td>{item.quantity}</td>
                                        <td>{item.purchasePrice}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr className='py-5'>
                                    <td></td>
                                    <td>
                                      <strong>
                                        {t(
                                          'orders:singleOrder.totalProductQuantity'
                                        )}
                                        :&emsp;
                                      </strong>
                                    </td>
                                    <td>{el.variants.length}</td>
                                  </tr>
                                </tfoot>
                              </table>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className='col-lg-3 col-md-12 col-sm-12 border-start p-3'>
                <h2 className='pb-4'>{t('orders:singleOrder.priceDetails')}</h2>
                <div className='table-responsive'>
                  <table className='table table-striped'>
                    <tbody>
                      <tr>
                        <td>
                          <h5 className='ms-1 py-2'>
                            <strong>{t('orders:singleOrder.subtotal')}:</strong>
                          </h5>
                        </td>
                        <td>
                          <h5>
                            {order.subTotal}
                            {i18n.language === 'en' ? 'EGP' : 'جنيهًا'}
                          </h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5 className='ms-1 py-2'>
                            <strong>{t('orders:singleOrder.taxes')}:</strong>
                          </h5>
                        </td>
                        <td>
                          <h5>
                            {order.taxes}
                            {i18n.language === 'en' ? 'EGP' : 'جنيهًا'}
                          </h5>
                        </td>
                      </tr>
                      <tr className='text-success'>
                        <td>
                          <h5 className='ms-1 py-2'>
                            <strong>
                              {t('orders:singleOrder.totalPrice')}:
                            </strong>
                          </h5>
                        </td>
                        <td>
                          <h5>
                            {order.totalPrice}
                            {i18n.language === 'en' ? 'EGP' : 'جنيهًا'}
                          </h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='card-footer'></div>
        </div>
      </div>
    </div>
  );
};
