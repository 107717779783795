import React, { useEffect } from 'react';
import { useFormContext, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { createDefaultSpecs } from '../../utils/helperFunctions';
import { SpecAttrs } from '../../utils/models/product';
import { SpecsFormValues } from '../../utils/types/specsEntry';

interface specsProps {
  specs?: SpecAttrs[];
}
const DEFUALTSPECS = [
  { name: 'Size', value: '', default: true },
  { name: 'Country of Origin', value: '', default: true },
  { name: 'Brand', value: '', default: true },
];
export const Specs: React.FC<specsProps> = (props) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
    reset,
    getValues,
    trigger,
  } = useFormContext(); // retrieve all hook methods
  const { fields, append, remove } = useFieldArray<SpecsFormValues>({
    name: 'specs',
  });
  useEffect(() => {
    reset(
      {
        ...getValues(),
        specs: props.specs ? createDefaultSpecs(props.specs) : DEFUALTSPECS,
      },
      { keepErrors: true }
    );
    trigger();
  }, [getValues, trigger, reset]);
  function AddSpec() {
    append({
      name: '',
      value: '',
      default: false,
    });
  }

  return (
    <div className='form-row'>
      <div className='col d-flex justify-content-between'>
        <p className='add-product-label'>
          {t('products:addProduct.specs.fieldName')}&nbsp;&nbsp;
        </p>
        <div className='table-responsive table-bordered w-75 mt-1 mb-1'>
          <table className='table table-bordered m-0'>
            <thead>
              <tr>
                <th>{t('products:addProduct.specs.name')}</th>
                <th>{t('products:addProduct.specs.value')}</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((el, i) => {
                return (
                  <tr key={el.id}>
                    <td className='p-0 align-top'>
                      {el.default && (
                        <div className='w-100'>
                          <input
                            className='w-100 p-2'
                            type='text'
                            value={el.name}
                            disabled
                          />
                          <br />
                        </div>
                      )}
                      <div className='w-100'>
                        <input
                          className='w-100 p-2'
                          type='text'
                          {...register(`specs[${i}].name` as const, {
                            required: 'Spec Name cannot be empty',
                            validate: {
                              notEmpty: (value) =>
                                value !== 'Spec Value' ||
                                `Please Enter a value for Spec Name`,
                            },
                          })}
                          hidden={el.default}
                          readOnly={el.default}
                          defaultValue={'Spec Name'}
                        />
                        {errors.specs && (
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>
                              {errors?.specs[i]?.spec?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </td>
                    <td className='p-0'>
                      <div className='w-100'>
                        <input
                          className='w-100 p-2'
                          type='text'
                          {...register(`specs[${i}].value` as const, {
                            required: `Please Enter a value for ${
                              el.name || 'This Spec'
                            }`,
                            validate: {
                              notEmpty: (value) =>
                                value !== 'Spec Value' ||
                                `Please Enter a value for ${
                                  el.name || 'This Spec'
                                }`,
                            },
                          })}
                          defaultValue='Spec Value'
                          onFocus={(e) => {
                            if (e.target.value === 'Spec Value')
                              e.target.value = '';
                          }}
                        />
                        {errors.specs && (
                          <div className='form-error-generic'>
                            <p className='p-0 m-0'>
                              {errors?.specs[i]?.value?.message}
                            </p>
                          </div>
                        )}
                      </div>

                      {!el.default && (
                        <div className='form-error-generic'>
                          <button
                            type='button'
                            style={{
                              marginLeft: '5px',
                              color: '#9b1717',
                              background: '#FFFF',
                              border: 'none',
                            }}
                            onClick={() => {
                              remove(i);
                            }}
                          >
                            [Delete]
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td>
                  <button
                    type='button'
                    style={{
                      border: 'none',
                      background: '#FFFF',
                    }}
                    onClick={AddSpec}
                  >
                    <em>Add Spec</em>
                  </button>
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
