import React from 'react';
import { useTranslation } from 'react-i18next';

interface loadingButtonProps {
  className: string;
  state: boolean;
  buttonName: string;
  percentage?: number;
}

export const LoadingButton: React.FC<loadingButtonProps> = ({
  className,
  state,
  buttonName,
  percentage,
}) => {
  const { t } = useTranslation();
  return (
    <button className={className} type='submit' disabled={state}>
      {state && (
        <span
          className='spinner-border spinner-border-sm'
          role='status'
          aria-hidden='true'
        ></span>
      )}
      <strong>
        {!state ? t(buttonName) : `${t('common:loading')} ${percentage}%`}
      </strong>
    </button>
  );
};
