import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../components/common/pagination';
import { Table } from '../components/common/table';
import { InventoryTableRow } from '../components/tableRows/inventoryTableRow';
import { OrderAttrs } from '../utils/models/orders';
import { ProductAttrs } from '../utils/models/product';
import { InventoryResponse } from '../utils/models/variants';
import { isInventory, isProducts } from '../utils/types/typeGuards';

interface inventoryProps {}

export const Inventory: React.FC<inventoryProps> = ({}) => {
  const { t } = useTranslation();
  const [products, setProducts] = useState<InventoryResponse[]>([]);
  function setProductsComp(data: any) {
    if (isInventory(data)) {
      // This is a type guard for my conscience
      setProducts(data);
    }
  }

  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid' id='page-content'>
        <h2 id='page-header'>{t('products:inventory')}&nbsp;</h2>
        <div className='card shadow' id='table-card'>
          <div className='card-header table-header py-3' id='table-card-header'>
            <h5 className='text-secondary table-card-heading'>
              <strong>{t('products:tableHeader')}</strong>
              <br />
            </h5>
          </div>
          <Pagination
            apiLink='/vendor/products/variants'
            setData={setProductsComp}
          >
            <Table
              column={[
                t('inventory:columnHeaders.productDetails'),
                t('inventory:columnHeaders.invDetails'),
                t('inventory:columnHeaders.status'),
                t('inventory:columnHeaders.replinsh'),
              ]}
            >
              {products.map((el) => {
                return <InventoryTableRow inventoryItem={el} />;
              })}
            </Table>
          </Pagination>
        </div>
      </div>
    </div>
  );
};
