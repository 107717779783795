import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../components/common/pagination';
import { Table } from '../components/common/table';
import { OrderTableRow } from '../components/tableRows/orderTableRow';
import { OrderAttrs } from '../utils/models/orders';
import { isOrders } from '../utils/types/typeGuards';

interface ordersProps {}

export const Orders: React.FC<ordersProps> = ({}) => {
  const [orders, setOrders] = useState<OrderAttrs[]>([]);
  const { t } = useTranslation();
  function setOrdersComp(data: any) {
    if (data.length === 0) return setOrders([]);
    if (isOrders(data)) {
      setOrders(data);
    }
  }
  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid' id='page-content'>
        <h2 id='page-header'>{t('orders:orders')}</h2>
        <div className='card shadow' id='table-card'>
          <div className='card-header table-header py-3' id='table-card-header'>
            <h5 className='text-secondary table-card-heading'>
              <strong>{t('orders:completeOrder')}</strong>
              <br />
            </h5>
          </div>
          <Pagination apiLink='/vendor/orders' setData={setOrdersComp}>
            {orders.length === 0 ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ fontSize: '3rem', color: '#222222' }}
              >
                <i className='fas fa-search'></i>
                <h3 className='p-0 m-0'>&nbsp;{t('orders:noOrdersFound')}</h3>
              </div>
            ) : (
              <Table
                column={[
                  t('orders:orderID'),
                  t('orders:date'),
                  t('orders:total'),
                  t('orders:status'),
                ]}
              >
                {orders.map((el) => {
                  return <OrderTableRow key={el._id} order={el} />;
                })}
              </Table>
            )}
          </Pagination>
        </div>
      </div>
    </div>
  );
};
