import React from 'react';

interface warningProps {
  message: string;
}

export const LoginWarning: React.FC<warningProps> = ({ message }) => {
  return (
    <div className='row m-0'>
      <div className='col'>
        <div className='d-flex align-items-stretch warning-login-box'>
          <div className='d-flex align-items-center warning-box-icon'>
            <i className='fas fa-exclamation-triangle m-2'></i>
          </div>
          <p className='mb-0 ms-2'>
            Warning: {message}
            <br />
          </p>
        </div>
      </div>
    </div>
  );
};
