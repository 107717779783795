import { LoginMessagesState } from '../pages/login';
import { LoginRouterState, LoginMessages } from './LoginAlerts';
import { ProductAttrs, ProductVariants, SpecAttrs } from './models/product';
import { SignUpInputs } from './types/signupTypes';
import {} from '../';
import { SpecsDataEntry } from './types/specsEntry';
export function ClearEmptyStringFromSignUp(data: SignUpInputs): SignUpInputs {
  const clonedData = Object.assign({}, data);
  if (clonedData.vendor.website === '') delete clonedData.vendor.website;
  if (clonedData.vendor.noOfEmployees === '')
    delete clonedData.vendor.noOfEmployees;
  return clonedData;
}

export function getMessageFromState(
  state: LoginRouterState
): LoginMessagesState {
  switch (state.reason) {
    case 'Email': {
      if (state.type === 'Error')
        return { error: LoginMessages.EmailAlreadyConfirmed };
      if (state.type === 'Success')
        return { success: LoginMessages.EmailConfirmerdSucessfully };
      return {};
    }
    case 'Signup': {
      if (state.type === 'Success')
        return { success: LoginMessages.SignUpSuccessful };
      return {};
    }
    case 'Password Change': {
      if (state.type === 'Success')
        return { success: LoginMessages.PasswordChanged };
      return {};
    }
    default: {
      return {};
    }
  }
}

export function getVariantFromProduct(
  product: ProductAttrs,
  variantID: string
): ProductVariants {
  const variant = (product.variants as ProductVariants[]).find(
    (el) => el._id === variantID
  );
  if (!variant) throw new Error('Variant not found');
  return variant;
}

export function createDefaultSpecs(specs: SpecAttrs[]) {
  const DEFUALTSPECS = ['Size', 'Country of Origin', 'Brand'];
  const ReturnArr: SpecsDataEntry[] = [];
  specs.forEach((el) => {
    const OBJ = {
      name: el.name,
      value: el.value,
      default: DEFUALTSPECS.includes(el.name),
    };
    ReturnArr.push(OBJ);
  });
  return ReturnArr;
}
