import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { BasicInfo } from '../components/AddProduct/BasicInfo';
import { DevTool } from '@hookform/devtools';
import { LoadingButton } from '../components/common/loadingButton';
import { TermsandCond } from '../components/AddProduct/termsandCond';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';
import APIFeatures, { S3Upload } from '../utils/axios';
import { ProductAttrs, ProductVariants } from '../utils/models/product';
import { CategoriesDisabled } from '../components/editProduct/categoriesDisabled';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';
import { SubCategory } from '../utils/models/category';
import { ItemTypeAttrs } from '../utils/models/itemTypes';
import Inputs from '../utils/types/addProductTypes';
import { Specs } from '../components/AddProduct/specs';
import { GlobalError } from '../components/common/globalError';
import { EditImages } from '../components/editProduct/editImages';
import { ProdListEdit } from '../components/editProduct/ProdListEdit';
import { PreviewEdit } from '../components/editProduct/previewEdit';
import { newProductRes } from '../utils/responses.interface';
import { ImageServices } from '../utils/services/imagesService';
import axios from 'axios';
import { Categories } from '../components/AddProduct/categories';

interface MatchParams {
  productSlug: string;
}
interface EditProductProps extends RouteComponentProps<MatchParams> {}

export const EditProduct: React.FC<EditProductProps> = (props) => {
  const history = useHistory();
  const { t } = useTranslation();
  const methods = useForm<Inputs>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [product, setProduct] = useState<ProductAttrs>();
  const [loading, setLoading] = useState(true);
  const [uploadData, setUploadData] = useState<Pick<newProductRes, 'images'>>();
  const [currentImages, setCurrentImages] = useState<string[]>([]);
  const [coverImages, setCoverImage] = useState<string>('default');
  const [error, setError] = useState();
  useEffect(() => {
    async function getProduct() {
      try {
        const uploadData = await APIFeatures.getImageUrlsEdit(
          props.match.params.productSlug
        );
        setUploadData(uploadData.data);
        const product = await APIFeatures.getOneProduct(
          props.match.params.productSlug
        );
        setProduct(product.data);
        methods.reset({
          arabicName: product.data.arabicName,
          name: product.data.name,
          description: product.data.description,
          coverImage: product.data.coverImage,
          MOQ: product.data.MOQ,
          baseQuantity: product.data.baseQuantity,
          mainVariantName: product.data.mainVariantName,
          itemsPerBox: product.data.itemsPerBox,
        });
        setCoverImage(product.data.coverImage);
        setCurrentImages(product.data.images);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    }
    getProduct();
  }, []);
  async function handleDataSubmit(data: Inputs) {
    if (!uploadData || !product) throw new Error('Failed to upload Images');
    let images = currentImages;
    try {
      setIsSubmitting(true);
      if (data.images !== undefined && data.images.length !== 0) {
        const formData = ImageServices.crateFormDataFromFiles(
          data.images,
          uploadData
        );
        const upload = await S3Upload.uploadImagesToS3(formData);
        images = [...images, ...upload];
      }
      await APIFeatures.patchOneProduct(data, product.slug, images);
      setIsSubmitting(false);
      history.push('/products');
    } catch (err) {
      setIsSubmitting(false);
      if (axios.isAxiosError(err)) {
        setError(err.response?.data.message);
      }
    }
  }
  if (loading) return <HeartSpinnerGlobal />;
  if (product && uploadData && currentImages)
    return (
      <div className='d-flex flex-fill' id='page-content-wrapper'>
        <div className='container-fluid' id='page-content'>
          <h2 id='page-header'>{t('products:title')}</h2>
          <div className='card shadow' id='table-card'>
            <div
              className='card-header table-header py-3'
              id='table-card-header'>
              <h5 className='text-secondary table-card-heading'>
                <strong>{t('products:addProduct.title')}</strong>
                <br />
              </h5>
            </div>
            <div className='card-body' id='table-card-control'>
              <form
                onSubmit={methods.handleSubmit(handleDataSubmit)}
                id='form-Add'>
                <div className='row'>
                  <FormProvider {...methods}>
                    <div className='col-sm-12 col-md-6'>
                      <div className='row'>
                        <div className='col'>
                          <h4>Product Display Details</h4>
                          <hr className='my-2' />
                        </div>
                      </div>
                      <BasicInfo />
                      <Categories
                        subCategory={product.category as SubCategory}
                        itemType={product.itemType as ItemTypeAttrs}
                      />
                      <Specs specs={product.specs} />
                      <EditImages
                        images={currentImages}
                        slug={product.slug}
                        setCoverImage={setCoverImage}
                        setCurrentImages={setCurrentImages}
                        uploadData={uploadData}
                      />
                    </div>
                    <div className='col-sm-12 col-md-6 d-flex'>
                      <div className='add-p-vertical-divider'></div>
                      <div style={{ width: '90%' }}>
                        {/*prod List*/}
                        <ProdListEdit
                          variants={product.variants as ProductVariants[]}
                          productSlug={product.slug}
                        />
                        <div className='form-row'>
                          <div className='col'>
                            <h1 className='price-section-heading'>
                              {t('products:addProduct.preview.title')}
                            </h1>
                          </div>
                        </div>
                        <div className='form-row d-flex align-items-start'>
                          <PreviewEdit coverImage={coverImages} />
                          <div className='col'>
                            <TermsandCond />
                            <div className='d-flex justify-content-end'>
                              <LoadingButton
                                className={
                                  'btn btn-success add-product-btn m-1 rounded'
                                }
                                buttonName={'products:editProductButton'}
                                state={isSubmitting}
                              />
                              {error && (
                                <div className='form-error-generic'>
                                  <p className='p-0 m-0'>{error}</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FormProvider>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  return <GlobalError />;
};
