import { OrderProductAttrs, ProductAttrs, ProductVariants } from './product';
export enum SalesOrderStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
}
interface ProductOrder {
  productSlug: string;
  quantity: number;
  sellingPrice: number;
}
export interface OrderAttrs {
  _id: string;
  status: SalesOrderStatus;
  createdAt: Date;
  vendorId: string;
  products: Array<ProductOrder>;
  totalPrice: number;
}

export interface ItemsAttrs {
  consumptionStrategy: string;
  quantity: number;
  sellingPrice: number;
  variantId: string;
}
export interface FullOrderProduct extends ProductOrder {
  productId: OrderProductAttrs;
  items: ItemsAttrs[];
  total: number | 0;
}

export interface ProductItems {
  product: {
    slug: string;
    name: string;
    arabicName: string;
    itemsPerBox: number;
    images: string[];
  };
  productId: string;
  variants: {
    productId: string;
    variantId: string;
    quantity: number;
    purchasePrice: number;
    productVariant: {
      vendorSku: string;
      name: string;
      arabicName: string;
    };
  }[];
}
export interface FullOrderAttrs extends OrderAttrs {
  items: ProductItems[];
  subTotal: number;
  taxes: number;
  deliveredAt: Date;
  _id: string;
  status: SalesOrderStatus;
  createdAt: Date;
  totalPrice: number;
}
