import React from 'react';

export const GlobalError: React.FC = () => {
  return (
    <div
      className='d-flex justify-content-center align-items-center'
      style={{ fontSize: '3rem', color: '#B80000' }}
    >
      <i className='fas fa-exclamation-circle'></i>
      <h3 className='p-0 m-0'>&nbsp;Sorry we ran into an Error</h3>
    </div>
  );
};
