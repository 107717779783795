import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';

import { Editor } from '@toast-ui/react-editor';
import React, { createRef, useEffect, useRef } from 'react';

interface DescriptionProps {
  text?: string;
  onChange: (value: string) => void;
}

export const Description: React.FC<DescriptionProps> = (props) => {
  const editorRef = createRef<Editor>();
  function handleChange() {
    if (!editorRef.current?.getInstance()) return;
    props.onChange(editorRef.current?.getInstance().getMarkdown());
  }
  useEffect(() => {
    const editorInstance = editorRef.current?.getInstance();
    if (!editorInstance) return;
    const editorUI = editorInstance?.getUI();
    const toolBar = editorUI.getToolbar();
    toolBar.removeItem(15);
    editorUI.setToolbar(toolBar);
    editorInstance.setUI(editorUI);
  }, [editorRef]);
  return (
    <Editor
      initialValue={props.text}
      initialEditType='wysiwyg'
      hideModeSwitch={true}
      onChange={handleChange}
      ref={editorRef}
    />
  );
};
