import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeartSpinnerTable } from '../components/common/heartSpinnerTable';
function Dashboard() {
  const { t } = useTranslation();
  return (
    <>
      <div className='d-flex flex-fill' id='page-content-wrapper'>
        <div className='container-fluid d-flex flex-column' id='page-content'>
          <div className='row'>
            <div className='col'>
              <h2 id='page-header'>{t('dashboard:title')}</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-9'>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-4'>
                  <div className='card shadow gray-dash-card mb-2'>
                    <div className='card-body'>
                      <div>
                        <h3 className='m-0'>Sales</h3>
                      </div>
                      <h6>
                        <em>Weekly</em>
                      </h6>
                      <div className='d-flex justify-content-end align-items-end align-content-end card-number-gray'>
                        <h5 className='m-0'>
                          <strong>0 EGP</strong>
                        </h5>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <a className='card-link-gray' href='#'>
                          <strong>Go to Sales</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4'>
                  <div className='card shadow gray-dash-card'>
                    <div className='card-body'>
                      <div>
                        <h3 className='m-0'>Orders</h3>
                        <h6>
                          <em>Weekly</em>
                        </h6>
                      </div>
                      <div className='d-flex justify-content-end align-items-end align-content-end card-number-gray'>
                        <h5 className='m-0'>
                          <strong>0 EGP</strong>
                        </h5>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <a className='card-link-gray' href='#'>
                          <strong>Go to Orders</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-4 col-xl-4'>
                  <div className='card shadow gray-dash-card'>
                    <div className='card-body'>
                      <div>
                        <h3 className='m-0'>Sales</h3>
                        <h6>
                          <em>Total</em>
                        </h6>
                      </div>
                      <div className='d-flex justify-content-end align-items-end align-content-end card-number-gray'>
                        <h5 className='m-0'>
                          <strong>0 EGP</strong>
                        </h5>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <a className='card-link-gray' href='#'>
                          <strong>Go to Sales</strong>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div className='card gray-dash-card'>
                    <div className='card-body shadow'>
                      <h4 className='card-title'>Total Sales Trends</h4>
                      <h6 className='text-muted card-subtitle mb-2'>
                        Per Week
                      </h6>
                      <h5 className='card-text text-center m-5' style = {{color:"#959595"}}>
                        This fucntion will start operating once the official
                        launch of the Platform starts.
                      </h5>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 col-md-12 col-lg-6'>
                  <div className='card gray-dash-card'>
                    <div className='card-body shadow'>
                      <h4 className='card-title'>Sales By Category</h4>
                      <h6 className='text-muted card-subtitle mb-2'>
                        Past Week
                      </h6>
                      <h5 className='card-text text-center m-5' style = {{color:"#959595"}}>
                        This fucntion will start operating once the official
                        launch of the Platform starts.
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='col-sm-12 col-md-12 col-lg-3'>
              <div className='card gray-dash-card'>
                <div className='card-body shadow'>
                  <h4 className='card-title'>Top Sellers</h4>
                  <h6 className='text-muted card-subtitle mb-2'>Weekly</h6>
                  <p className='card-text'></p>
                  <div className='row'>
                    <div className='col-12'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='justify-content-center m-0' />
                    </div>
                    <div className='col-12 justify-content-center'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='justify-content-center m-0' />
                    </div>
                    <div className='col-12 justify-content-center'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='justify-content-center m-0' />
                    </div>
                    <div className='col-12 justify-content-center'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='justify-content-center m-0' />
                    </div>
                    <div className='col-12 justify-content-center'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='justify-content-center m-0' />
                    </div>
                    <div className='col-12 justify-content-center'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='justify-content-center m-0' />
                    </div>
                    <div className='col-12'>
                      <h2 className='d-flex justify-content-center'>-</h2>
                      <hr className='m-0' />
                    </div>
                  </div>
                  <a className='card-link' href='#'></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
