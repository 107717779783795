import React, { useEffect, useRef, useState } from 'react';
import { PublicApiFeatures } from '../../utils/axios';
import { useTranslation } from 'react-i18next';
import { SubCategory, TopCategory } from '../../utils/models/category';
import { ItemTypeAttrs } from '../../utils/models/itemTypes';
import { useFormContext } from 'react-hook-form';
import Inputs from '../../utils/types/addProductTypes';
import { HeartSpinnerTable } from '../common/heartSpinnerTable';
interface categoriesProps {
  subCategory?: SubCategory;
  itemType?: ItemTypeAttrs;
}

export const Categories: React.FC<categoriesProps> = (props) => {
  const [topCategories, setTopCategories] = useState<
    Pick<TopCategory, '_id' | 'arabicName' | 'children' | 'name'>[]
  >([]);
  const [subCategories, setSubCategories] = useState<SubCategory[]>([]);
  const [itemTypes, setItemTypes] = useState<ItemTypeAttrs[]>([]);
  const { t, i18n } = useTranslation();
  const { register, watch, setValue } = useFormContext<Inputs>();
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [intitalTopCat, setIntialTopCat] = useState<number | undefined>();
  const currentSubCategory = watch('categoryId');
  function OnTopCategoryChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setIntialTopCat(undefined);
    const ind = Number(e.target.value);
    console.log(topCategories[ind]);
    setSubCategories(topCategories[ind].children);
    setValue('categoryId', topCategories[ind].children[0]._id);
  }
  useEffect(() => {
    async function initCategoriesDefault() {
      const res = await PublicApiFeatures.getCategories();
      setTopCategories(res.data.data);
      setSubCategories(res.data.data[0].children);
      setValue('categoryId', res.data.data[0].children[0]._id);
    }
    async function initCategoriesNonDefault(
      subCategory: SubCategory,
      itemType: ItemTypeAttrs
    ) {
      setCategoryLoading(true);
      const res = await PublicApiFeatures.getCategories();
      setTopCategories(res.data.data);
      setValue('categoryId', subCategory._id);
      const topCatInd = res.data.data.findIndex(
        (el) => el._id === subCategory.parentId
      );
      setIntialTopCat(topCatInd);
      setSubCategories(res.data.data[topCatInd].children);
      setItemTypes(subCategory.itemTypes);
      setCategoryLoading(false);
    }

    if (props.subCategory && props.itemType) {
      initCategoriesNonDefault(props.subCategory, props.itemType);
    } else {
      initCategoriesDefault();
    }
  }, []);

  useEffect(() => {
    async function getCategoryData() {
      if (currentSubCategory && currentSubCategory.length > 0) {
        setCategoryLoading(true);
        const res = await PublicApiFeatures.getOneCategory(currentSubCategory);
        setItemTypes(res.data.itemTypes);
        if (currentSubCategory === props.subCategory?._id && props.itemType) {
          setValue('itemTypeId', props.itemType._id);
        } else {
          setValue('itemTypeId', res.data.itemTypes[0]._id);
        }
        setCategoryLoading(false);
      }
    }
    getCategoryData();
  }, [currentSubCategory]);

  return (
    <>
      <div className='form-row'>
        <div className='col'>
          <label className='col-form-label d-flex justify-content-between align-items-center add-product-label'>
            {t('products:addProduct.categories.category')}&nbsp;&nbsp;
            <select
              className='form-control w-75'
              name='topCategory'
              onChange={OnTopCategoryChange}
              value={intitalTopCat ? intitalTopCat : undefined}
            >
              {topCategories.map((el, i: number) => {
                return (
                  <option value={i} key={el._id}>
                    {i18n.language === 'ar' ? el.arabicName : el.name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      {categoryLoading ? (
        <HeartSpinnerTable />
      ) : (
        <>
          <div className='form-row'>
            <div className='col'>
              <label className='col-form-label d-flex justify-content-between align-items-center add-product-label'>
                {t('products:addProduct.categories.subCategory')}&nbsp;&nbsp;
                <select
                  className='form-control w-75'
                  {...register('categoryId')}
                >
                  {subCategories?.map((el, i) => {
                    return (
                      <option value={el._id} key={el._id}>
                        {i18n.language === 'ar' ? el.arabicName : el.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
          <div className='form-row'>
            <div className='col'>
              <label className='col-form-label d-flex justify-content-between align-items-center add-product-label'>
                {t('products:addProduct.categories.itemType')}&nbsp;&nbsp;
                <select
                  className='form-control w-75'
                  {...register('itemTypeId')}
                >
                  {itemTypes.map((el) => {
                    return (
                      <option value={el._id} key={el._id}>
                        {i18n.language === 'ar' ? el.arabicName : el.name}
                      </option>
                    );
                  })}
                </select>
              </label>
            </div>
          </div>
        </>
      )}
    </>
  );
};
