import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from '../components/common/pagination';
import { Table } from '../components/common/table';
import { ProductTableRow } from '../components/tableRows/productTableRow';
import { ProductAttrs } from '../utils/models/product';
import { isProducts } from '../utils/types/typeGuards';
import { Link } from 'react-router-dom';
interface productsProps {}

export const Products: React.FC<productsProps> = () => {
  const { t, i18n } = useTranslation();
  const [Products, setProducts] = useState<ProductAttrs[]>([]);
  function setProductsComp(data: any) {
    if (data.length === 0) return setProducts([]);
    if (isProducts(data)) {
      // This is a type guard from my conscience
      setProducts(data);
    }
  }
  const button = (
    <Link to='/products/add-product'>
      <button className='btn btn-success add-product-btn m-1' type='button'>
        {t('products:addProduct.button')}
        <i
          className={`fa fa-plus ${
            i18n.language === 'en' ? 'ms-1' : 'me-1'
          } text-end`}
        ></i>
      </button>
    </Link>
  );
  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid' id='page-content'>
        <h2 id='page-header'>{t('products:title')}</h2>
        <div className='card shadow' id='table-card'>
          <div className='card-header table-header py-3' id='table-card-header'>
            <h5 className='text-secondary table-card-heading'>
              <strong>{t('products:tableHeader')}</strong>
              <br />
            </h5>
          </div>
          <Pagination
            apiLink={'/vendor/products'}
            setData={setProductsComp}
            button={button}
          >
            {Products.length === 0 ? (
              <div
                className='d-flex justify-content-center align-items-center'
                style={{ fontSize: '3rem', color: '#222222' }}
              >
                <i className='fas fa-search'></i>
                <h3 className='p-0 m-0'>&nbsp;No Products Found</h3>
              </div>
            ) : (
              <Table
                column={[
                  t('products:columnHeaders.productDetails'),
                  t('products:columnHeaders.listingDetails'),
                  t('products:columnHeaders.status'),
                  t('products:columnHeaders.actions'),
                ]}
                checkbox={<input type='checkbox' />}
              >
                {Products.map((el) => {
                  return <ProductTableRow product={el} key={el._id} />;
                })}
              </Table>
            )}
          </Pagination>
        </div>
      </div>
    </div>
  );
};
