import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from 'axios';
import config from './config';
import { SubCategory, TopCategory } from './models/category';
import { imagesUploadReq } from './services/imagesService';
import {
  RefreshToken,
  Login,
  CategoryRes,
  Pagination,
  newProductRes,
  PersonalDetails,
} from './responses.interface';
import { FullOrderAttrs } from './models/orders';
import { SignUpInputs } from './types/signupTypes';
import History from './history';
import LocalStorageService from './services/localStorageService';
import Inputs from './types/addProductTypes';
import { ProductAttrs, ProductVariants } from './models/product';
import { ProductVariantsInputs } from './types/productVairantInputs';
import { InventoryResponse } from './models/variants';

export interface PersonalData {
  contactPerson: {
    firstName: string;
    lastName: string;
  };
}

export interface PasswordData {
  password: string;
  passwordConfirm: string;
}
interface PaginationData {
  apiLink: string;
  limit: number;
  page: number;
}
interface AxiosErrInterceptor extends AxiosRequestConfig {
  _retry: boolean;
}
abstract class HttpClient {
  protected readonly instance: AxiosInstance;
  public constructor(baseUrl: string) {
    this.instance = axios.create({
      baseURL: baseUrl,
    });
    this.initResponseInterceptor();
  }
  private initResponseInterceptor = () => {
    this.instance.interceptors.response.use(undefined, this._handleError);
  };
  protected _handleError = (error: any) => Promise.reject(error);
}

class OpenApi extends HttpClient {
  public constructor() {
    super(config.REACT_APP_API);
  }
  public getCategories = async () => {
    const res = await this.instance.get<CategoryRes>('/categories/parents');
    return res;
  };
  public getOneCategory = async (categoryId: string) => {
    const res = await this.instance.get<SubCategory | TopCategory>(
      `/categories/${categoryId}`
    );
    return res;
  };
  public SignUp = async (data: SignUpInputs) => {
    data.userType = 'Vendor';
    const res = await this.instance.post('/users/signup', data);
    return res;
  };
  public ConfirmEmail = async (token: string) => {
    const res = await this.instance.post(`/users/confirm_email/${token}`);
    return res;
  };
  public RequestPasswordReset = async (username: string) => {
    const res = await this.instance.post('/users/reset_password', { username });
    return res;
  };
  public ResetPassword = async (passwordData: PasswordData, token: string) => {
    const res = await this.instance.post(
      `users/reset_password/${token}`,
      passwordData
    );
    return res;
  };
}
export const PublicApiFeatures = new OpenApi();

class imageUploadApi extends HttpClient {
  constructor() {
    super('');
  }
  public uploadImagesToS3 = async (requests: Array<imagesUploadReq>) => {
    const uploadedUrls: string[] = [];
    const axiosRequests = requests.map((el) => {
      const key = el.form.get('key');
      console.log(typeof key);
      if (typeof key === 'string') uploadedUrls.push(key);
      return this.instance.post(el.url, el.form, {
        headers: {
          'Content-Type': 'multipart/form-data; boundary=${form._boundary}',
        },
      });
    });
    await Promise.all(axiosRequests);
    return uploadedUrls;
  };
}
export const S3Upload = new imageUploadApi();
class ProtectedApi extends HttpClient {
  private interceptorId: number;
  public constructor() {
    super(config.REACT_APP_API);
    this.interceptorId = this.AddRefreshInterceptor();
    this.instance.defaults.withCredentials = true;
  }
  private setToken(token: string) {
    this.instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }
  private AddRefreshInterceptor() {
    const interceptor = this.instance.interceptors.response.use(
      undefined,
      this.RefreshInterceptor
    );
    return interceptor;
  }
  private EjectInterceptor() {
    this.instance.interceptors.response.eject(this.interceptorId);
  }
  public async RequestNewRefreshTokenGlobal() {
    const res = await axios.post<RefreshToken>(
      '/sessions/refresh_tokens',
      undefined,
      {
        baseURL: config.REACT_APP_API,
        withCredentials: true,
      }
    );
    return res.data;
  }
  private async RequestNewRefreshToken() {
    try {
      const token = await this.RequestNewRefreshTokenGlobal();
      this.setToken(token.accessToken);
      return token;
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 401)
          History.push('/', {
            type: 'Timeout',
            reason: 'REFRESHTOKEN',
          });
      }
      throw err;
    }
  }
  private RefreshInterceptor = async (err: AxiosError) => {
    const originalConfig: AxiosErrInterceptor = {
      ...err.config,
      _retry: false,
    };
    if (err.response?.status === 401 && !originalConfig._retry) {
      const newToken = await this.RequestNewRefreshToken();
      originalConfig._retry = true;
      originalConfig.headers.Authorization = `Bearer ${newToken.accessToken}`;
      return this.instance(originalConfig);
    }
    return Promise.reject(err);
  };
  public Login = async (credentials: {
    username: string;
    password: string;
  }) => {
    try {
      this.EjectInterceptor();
      const res = await this.instance.post<Login>('/sessions/login', {
        ...credentials,
        userType: 'Vendor',
      });
      if (!res.data.isEmailConfirmed) return res;
      this.setToken(res.data.accessToken);
      this.AddRefreshInterceptor();
      return res;
    } catch (err) {
      throw err;
    }
  };
  public Logout = async () => {
    await this.instance.post('/sessions/logout');
  };

  public getDataWithPagination = async (
    dataFilters: PaginationData,
    cancelTokenSource: CancelTokenSource
  ) => {
    try {
      const res = await this.instance.get<Pagination>(dataFilters.apiLink, {
        params: {
          page: dataFilters.page,
          limit: dataFilters.limit,
        },
        cancelToken: cancelTokenSource.token,
      });
      return res;
    } catch (err) {
      throw err;
    }
  };
  public getOneProduct = async (productSlug: string) => {
    const res = await this.instance.get<ProductAttrs>(
      `/vendor/products/${productSlug}`
    );
    return res;
  };
  public getOneVariant = async (productSlug: string, variantId: string) => {
    const res = await this.instance.get<InventoryResponse>(
      `/vendor/products/${productSlug}/variants/${variantId}`
    );
    return res;
  };
  public getImageUrls = async () => {
    const res = await this.instance.get<newProductRes>('/vendor/products/new');
    return res;
  };
  public getImageUrlsEdit = async (productSlug: string) => {
    const res = await this.instance.get<Pick<newProductRes, 'images'>>(
      `/vendor/products/${productSlug}/images/new`
    );
    return res;
  };
  public getOneOrder = async (orderId: string) => {
    const res = await this.instance.get<FullOrderAttrs>(
      `/vendor/orders/${orderId}`
    );
    return res;
  };

  public postOneProduct = async (data: Inputs, images: string[]) => {
    const res = await this.instance.post('/vendor/products', {
      ...data,
      images,
    });
    return res;
  };

  public patchOneVariant = async (
    variantData: Partial<ProductVariants>,
    variantId: string,
    productSlug: string
  ) => {
    delete variantData.price;
    const res = await this.instance.patch<ProductVariants>(
      `/vendor/products/${productSlug}/variants/${variantId}`,
      variantData
    );
    return res;
  };

  public postOneVariant = async (
    variantData: ProductVariantsInputs,
    slug: string
  ) => {
    const res = await this.instance.post<ProductVariants>(
      `/vendor/products/${slug}/variants`,
      variantData
    );
    return res;
  };
  public patchOneProduct = async (
    productData: Inputs,
    slug: string,
    images: string[]
  ) => {
    const res = await this.instance.patch(`/vendor/products/${slug}`, {
      ...productData,
      images,
    });
    return res;
  };

  public deleteOneImage = async (slug: string, imageId: string) => {
    await this.instance.delete(`/vendor/products/${slug}/images/${imageId}`);
  };
  public getPersonalDetails = async () => {
    const res = await this.instance.get<PersonalDetails>(
      '/vendor/profile/personal'
    );
    return res;
  };
  public updateProfileData = async (data: PersonalData) => {
    await this.instance.patch('/vendor/profile', data);
  };
}

const APIFeatures = new ProtectedApi();
export default APIFeatures;
