import React, { useEffect, useState } from 'react';
import { ProductVariants } from '../../utils/models/product';
import { useFormContext } from 'react-hook-form';
import Inputs from '../../utils/types/addProductTypes';
import { minimum } from '../../utils/addProductValidation';
import { ItemModalEdit } from '../editProduct/itemModalEdit';
import { useTranslation } from 'react-i18next';
interface ProdListProps {
  variants: ProductVariants[];
  productSlug: string;
}
export interface ModalState {
  state: boolean;
  editVariant?: ProductVariants;
}
export const ProdListEdit: React.FC<ProdListProps> = ({
  variants,
  productSlug,
}) => {
  const [items, setItems] = useState<ProductVariants[]>(variants);
  const [modal, setModal] = useState<ModalState>({
    state: false,
  });
  const { register } = useFormContext<Inputs>();
  function handleOpen(variant?: ProductVariants) {
    setModal({
      state: true,
      editVariant: variant,
    });
  }
  function handleDeleteVariant() {
    // Call API
  }
  const { t } = useTranslation();
  return (
    <>
      {modal.state && (
        <ItemModalEdit
          setModal={setModal}
          setItems={setItems}
          variant={modal.editVariant}
          slug={productSlug}
        />
      )}
      <div className='row'>
        <div className='col'>
          <h4>{t('products:addProduct.ProdList.title')}</h4>
          <hr className='my-2' />
          <p className='mb-2'>{t('products:addProduct.ProdList.subtitle')}</p>
          <div className='row'>
            <div className='col d-flex justify-content-end'>
              <div className='product-item-list-container'>
                <div className='table-responsive product-item-list-table'>
                  <table className='table table-bordered m-0'>
                    <thead>
                      <tr>
                        <th>
                          {t('products:addProduct.ProdList.table.nameEN')}
                        </th>
                        <th>
                          {t('products:addProduct.ProdList.table.nameAR')}
                        </th>
                        <th>
                          {t('products:addProduct.ProdList.table.editDel')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((el, i) => {
                        return (
                          <tr key={el._id}>
                            <td>{el.name}</td>
                            <td>{el.arabicName}</td>
                            <td className='text-center'>
                              <i
                                className='fas fa-pen mx-1'
                                onClick={() => {
                                  handleOpen(el);
                                }}
                              ></i>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col text-end d-flex'>
          <button
            className='btn btn-danger add-product-btn m-2 rounded w-50'
            type='button'
            disabled
          >
            <strong>{t('products:addProduct.ProdList.clear')}</strong>
          </button>
          <button
            className='btn btn-success add-product-btn m-2 rounded w-50'
            type='button'
            onClick={() => {
              handleOpen();
            }}
          >
            <strong>{t('products:addProduct.ProdList.addItem')}</strong>
          </button>
        </div>
      </div>
      <hr className='my-2' />
      <div className='row'>
        <div className='col-12'>
          <label className='col-form-label d-flex flex-row justify-content-between align-items-center align-items-xxl-start add-product-label w-100'>
            {t('products:addProduct.minimumOrder')}&nbsp;&nbsp;
            <input
              className='form-control flex-grow-1 w-50'
              type='number'
              {...register('MOQ', { ...minimum, valueAsNumber: true })}
            />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <label className='col-form-label d-flex flex-row justify-content-between align-items-center align-items-xxl-start add-product-label w-100'>
            {t('products:addProduct.baseQuantity')}&nbsp;&nbsp;
            <input
              className='form-control flex-grow-1 w-50'
              type='number'
              {...register('baseQuantity', { ...minimum, valueAsNumber: true })}
            />
          </label>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <label className='col-form-label d-flex flex-row justify-content-between align-items-center align-items-xxl-start add-product-label w-100'>
            {t('products:addProduct.itemsPerBox')}&nbsp;&nbsp;
            <input
              className='form-control flex-grow-1 w-50'
              type='number'
              {...register('itemsPerBox', { ...minimum, valueAsNumber: true })}
            />
          </label>
        </div>
      </div>
    </>
  );
};
