import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import APIFeatures, { PersonalData } from '../../utils/axios';
import { PersonalDetails } from '../../utils/responses.interface';
import { HeartSpinnerTable } from '../common/heartSpinnerTable';

interface personalProps {}

export const Personal: React.FC<personalProps> = ({}) => {
  const { t } = useTranslation();
  const [Loading, setLoading] = useState<boolean>(true);
  const [userInfo, setUserInfo] = useState<PersonalDetails>();
  const [editState, setEditState] = useState(false);
  const [newData, setNewData] = useState<PersonalData>({
    contactPerson: {
      firstName: '',
      lastName: '',
    },
  });
  useEffect(() => {
    async function getData() {
      setLoading(true);
      const res = await APIFeatures.getPersonalDetails();
      setUserInfo(res.data);
      setNewData({
        contactPerson: {
          firstName: res.data.firstname,
          lastName: res.data.lastname,
        },
      });
      setLoading(false);
    }
    getData();
  }, []);
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setNewData({
      contactPerson: {
        ...newData.contactPerson,
        [e.currentTarget.name]: e.currentTarget.value,
      },
    });
  }
  if (Loading) return <HeartSpinnerTable />;
  return (
    <>
      <div className='col'>
        <div className='profile-page-main p-3'>
          <div className='row'>
            <div className='col'>
              <h3>
                <i className='fas fa-user me-2'></i>
                {t('profile:personalDetails.header')}
              </h3>
            </div>
            <div className='col d-flex justify-content-end'>
              <button className='btn btn-warning me-2' type='button'>
                <i className='fas fa-key me-1'></i>
                {t('profile:personalDetails.changePassword')}
              </button>
              <button className='btn btn-secondary me-2' type='button'>
                <i className='fas fa-pen me-1'></i>
                {t('profile:edit')}
              </button>
            </div>
          </div>
          <div className='row'>
            <div className='col'>
              <form>
                <div className='form-row'>
                  <div className='col ps-3 pe-3'>
                    <div className='form-row'>
                      <div className='col'>
                        <label className='col-form-label d-flex flex-column flex-grow-1'>
                          {t('profile:personalDetails.fields.firstName')} <br />
                          <input
                            className='form-control'
                            type='text'
                            name='firstName'
                            disabled={editState}
                            value={newData.contactPerson.firstName}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col'>
                        <label className='col-form-label d-flex flex-column flex-grow-1'>
                          {t('profile:personalDetails.fields.lastName')} <br />
                          <input
                            className='form-control'
                            type='text'
                            name='lastName'
                            disabled={editState}
                            value={newData.contactPerson.lastName}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col'>
                        <label className='col-form-label d-flex flex-column flex-grow-1'>
                          {t('profile:personalDetails.fields.phone')}
                          <br />
                          <input
                            name='phone'
                            className='form-control'
                            type='text'
                            value={userInfo?.phone}
                            disabled
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className='col ps-3 pe-3'>
                    <div className='form-row'>
                      <div className='col'>
                        <label className='col-form-label d-flex flex-column flex-grow-1'>
                          {t('profile:personalDetails.fields.email')}
                          <br />
                          <input
                            className='form-control'
                            name='email'
                            type='text'
                            value={userInfo?.email}
                            disabled
                          />
                        </label>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col'>
                        <label className='col-form-label d-flex flex-column flex-grow-1'>
                          {t('profile:personalDetails.fields.username')} <br />
                          <input
                            className='form-control'
                            type='text'
                            disabled
                            value={userInfo?.username}
                          />
                        </label>
                      </div>
                    </div>
                    <div className='form-row'>
                      <div className='col d-flex flex-column justify-content-end'>
                        <div>
                          <p className='mt-3'>
                            {t('profile:personalDetails.footerMsg')}
                          </p>
                        </div>
                        <div className='d-flex justify-content-end'>
                          <button className='btn btn-danger me-1' type='button'>
                            {t('profile:cancel')}{' '}
                          </button>
                          <button className='btn btn-success' type='button'>
                            {t('profile:saveChanges')}{' '}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
