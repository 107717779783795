import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { newProductRes } from '../../utils/responses.interface';
import { ImageServices } from '../../utils/services/imagesService';
interface imagesProps {
  setCoverImage: React.Dispatch<React.SetStateAction<string>>;
  uploadData: newProductRes;
}

export const Images: React.FC<imagesProps> = ({
  setCoverImage,
  uploadData,
}) => {
  const { t } = useTranslation();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { register, setValue } = useFormContext();
  const [images, setImages] = useState<string[]>([]);
  useEffect(() => {
    register('images', { required: true });
    register('coverImage', { required: true });
  }, [register]);
  function handleImages(e: React.ChangeEvent<HTMLInputElement>) {
    if (
      e.target.files?.length === undefined ||
      e.target.files.length > uploadData.images.length
    )
      return; // add error handling
    setImages(ImageServices.createUrlFromFile(e.target.files));
    setValue('images', e.target.files);
  }
  function handleUpClick() {
    hiddenFileInput?.current?.click();
  }
  function handleClearImg() {
    setImages([]);
    setValue('images', undefined);
    setValue('coverImage', undefined);
  }
  function handleCoverImage(id: string, index: number) {
    setValue('coverImage', id);
    setCoverImage(images[index]);
  }
  return (
    <>
      <div className='form-row'>
        <div className='col d-flex justify-content-between'>
          <p className='add-product-label'>
            {t('products:addProduct.addImage.images')}&nbsp;&nbsp;
          </p>
          <div className='d-flex justify-content-center align-items-center border w-75 image-upload-box rounded text-center p-3'>
            <button
              className='btn btn-secondary'
              type='button'
              onClick={handleUpClick}
            >
              {t('products:addProduct.addImage.uplImages')}

              <i
                className='fas fa-file-upload p-2'
                style={{ fontSize: 'larger' }}
              ></i>
            </button>

            <input
              type='file'
              className='custom-file-input d-none'
              id='customFile'
              multiple
              onChange={handleImages}
              accept='image/*'
              ref={hiddenFileInput}
            />
          </div>
        </div>
      </div>

      <div className='form-row'>
        <div className='col d-flex justify-content-end'>
          <div className='align-items-center border w-75 image-upload-box rounded'>
            <p className='uploadImgParagraph'>
              {t('products:addProduct.selectCoverImage')}
            </p>
            <div className='image-preview p-2 text-center w-100'>
              {images.map((el, i) => {
                return (
                  <>
                    <input
                      type='radio'
                      name='images'
                      onChange={(e) => {
                        handleCoverImage(e.target.id, i);
                      }}
                      id={uploadData.images[i].key}
                      style={{ display: 'none' }}
                    />
                    <label
                      htmlFor={uploadData.images[i].key}
                      className='imgSelContainer shadow m-1'
                    >
                      <div className='image-preview-crop shadow'>
                        <img
                          src={el}
                          className='image-preview-img'
                          alt={`imgPreview-${i}`}
                        />
                      </div>
                    </label>
                  </>
                );
              })}
            </div>
            <div className='text-end'>
              <p
                className='me-2 mb-1'
                onClick={handleClearImg}
                style={{ cursor: 'pointer', color: '#8b0000' }}
              >
                {t('products:addProduct.addImage.clearImg')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
