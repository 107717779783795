import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend'; //This is not Hussien's backend :)
const detectionOptions = {
  order: ['cookie', 'localStorage', 'sessionStorage'],
  caches: ['cookie', 'localStorage', 'sessionStorage'],
  cookieMinutes: 10080, //1 week
};
i18n
  .use(initReactI18next)
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    detection: detectionOptions,
    ns: [
      'dashboard',
      'products',
      'common',
      'navigation',
      'inventory',
      'signup',
      'profile',
      'inventoryReplenish',
      'orders',
      'pagination',
    ],
    supportedLngs: ['ar', 'en'],
    debug: process.env.NODE_ENV === 'development' ? true : false,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
