import React from 'react';

interface tableProps {
  column: string[];
  checkbox?: JSX.Element;
}

export const Table: React.FC<tableProps> = (props) => {
  return (
    <div className='table-responsive'>
      <table className='table'>
        <thead>
          <tr>
            {props.checkbox ? <th>{props.checkbox}</th> : null}
            {props.column.map((el, i) => {
              return <th key={i}>{el}</th>;
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>{props.children}</tbody>
      </table>
    </div>
  );
};
