import React from 'react';
import { FieldErrors } from 'react-hook-form';
interface FormErrorProps {
  errors: FieldErrors;
  name: string;
}

export const FormError: React.FC<FormErrorProps> = ({ errors, name }) => {
  return (
    <div className='form-error-generic'>
      <p className='p-0 m-0'>{errors[name].message}</p>
    </div>
  );
};
