export const nameValidation = {
  required: 'Product Name is required',
};

export const descriptionValidation = {
  required: 'Description is Required',
  minLength: {
    value: 10,
    message: 'Product Description Cannot be less than 10',
  },
};

export const priceValidation = {
  required: 'Price is Required',
  min: {
    value: 0,
    message: 'Can not sell a product for less than 1 EGP',
  },
};
export const saleQty = {
  required: 'Base quantity is required',
  min: {
    value: 1,
    message: 'Cannot sell less than 1 product',
  },
};

export const minimum = {
  required: 'Minimum Order Quantity is required',
  min: {
    value: 1,
    message: 'Cannot sell less than 1 product',
  },
};

export const termsValidation = {
  required: 'Please accept terms and conditions',
};
