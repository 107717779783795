import React, { useEffect } from 'react';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import HeartSpinnerGlobal from '../components/common/globalSpinnner';
import { PublicApiFeatures } from '../utils/axios';
import { LoginRouterState } from '../utils/LoginAlerts';
interface MatchParams {
  token: string;
}
interface EmailConfirmationProps extends RouteComponentProps<MatchParams> {}
export const EmailConfirmation: React.FC<EmailConfirmationProps> = (props) => {
  const history = useHistory();
  const ConfirmationState: LoginRouterState = {
    reason: 'Email',
    type: 'Success',
  };
  useEffect(() => {
    async function ConfirmEmail() {
      try {
        await PublicApiFeatures.ConfirmEmail(props.match.params.token);
        history.push('/', ConfirmationState);
      } catch (err) {
        ConfirmationState.type = 'Error';
        history.push('/', ConfirmationState);
      }
    }
    ConfirmEmail();
  }, []);
  return (
    <>
      <div className='d-flex flex-fill' id='page-content-wrapper'>
        <div
          className='container-fluid d-flex justify-content-center'
          id='page-content'
        >
          <div className='d-flex justify-content-center align-items-center vh-100 w-100'>
            <div>
              <HeartSpinnerGlobal />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
