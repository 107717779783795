import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Personal } from '../components/profile/personal';
interface ProfileProps {}
export const Profile: React.FC<ProfileProps> = ({}) => {
  const { t } = useTranslation();
  return (
    <div className='d-flex flex-fill' id='page-content-wrapper'>
      <div className='container-fluid d-flex flex-column' id='page-content'>
        <div className='row'>
          <div className='col'>
            <h2 id='page-header'>{t('profile:pageHeader')}</h2>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <div className='card shadow' id='table-card'>
              <div
                className='card-header table-header py-3'
                id='table-card-header'
              >
                <h5 className='text-secondary table-card-heading'>
                  <strong>{t('profile:tableHeader')}</strong>
                  <br />
                </h5>
              </div>
              <div className='card-body p-0' id='table-card-control'>
                <div className='row'>
                  <div className='col-auto profile-nav-col p-0'>
                    <ul className='nav nav-pills flex-column p-0'>
                      <li className='nav-item'>
                        <NavLink
                          to='/profile/personal'
                          activeClassName='disabled active profile-nav-active'
                          className='nav-link profile-nav-link'
                        >
                          <i className='fas fa-user me-1'></i>
                          {t('profile:navigation.personalDetails')}
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink
                          to='/profile/company'
                          activeClassName='disabled active profile-nav-active'
                          className='nav-link profile-nav-link'
                        >
                          <i className='fas fa-building me-1'></i>
                          {t('profile:navigation.companyDetails')}
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink
                          to='/profile/legal'
                          activeClassName='disabled active profile-nav-active'
                          className='nav-link profile-nav-link'
                        >
                          <i className='fas fa-balance-scale me-1'></i>
                          {t('profile:navigation.legalDetails')}
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink
                          to='/profile/contacrs'
                          activeClassName='disabled active profile-nav-active'
                          className='nav-link profile-nav-link'
                        >
                          <i className='fas fa-address-book me-1'></i>
                          {t('profile:navigation.companyContacts')}
                        </NavLink>
                      </li>
                      <li className='nav-item'>
                        <NavLink
                          to='/profile/settings'
                          activeClassName='disabled active profile-nav-active'
                          className='nav-link profile-nav-link'
                        >
                          <i className='fas fa-wrench me-1'></i>
                          {t('profile:navigation.siteSettings')}
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                  <Route path='/profile/personal' component={Personal} />
                  <Route path='/profile/company' />
                  <Route path='/profile/legal' />
                  <Route path='/profile/contacts' />
                  <Route path='/profile/settings' />
                </div>
                <hr className='m-0' />
                <div
                  className='card-body'
                  id='table-card-footer'
                  style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                ></div>
                <div
                  className='card-body'
                  id='table-card-footer-1'
                  style={{
                    paddingTop: '0.5rem',
                    paddingBottom: '0.5rem',
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
