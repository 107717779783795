import React, {
  useEffect,
  useState,
  MouseEvent,
  PropsWithChildren,
} from 'react';
import { useTranslation } from 'react-i18next';
import APIFeatures from '../../utils/axios';
import { OrderAttrs } from '../../utils/models/orders';
import { ProductAttrs } from '../../utils/models/product';
import { GlobalError } from './globalError';
import { HeartSpinnerTable } from './heartSpinnerTable';
import axios from 'axios';
interface paginationProps {
  apiLink: string;
  setData: (data: ProductAttrs[] | OrderAttrs[]) => void;
  button?: JSX.Element;
}
enum PageState {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  LOADED = 'LOADED',
}
export function Pagination(props: PropsWithChildren<paginationProps>) {
  const { t, i18n } = useTranslation();
  const { apiLink, setData, button } = props;
  const [limit, setLimit] = useState(5);
  const [page, setPage] = useState(1);
  const [totalInfo, setTotalInfo] = useState({
    skip: 0,
    maxPages: 0,
    total: 0,
  });
  const [currentState, setCurrentState] = useState<string>(PageState.LOADING);
  function handlePagination(e: MouseEvent<HTMLButtonElement>) {
    if (e.currentTarget.id === 'increment') {
      setPage((prevState) => {
        if (prevState === totalInfo.maxPages) return prevState;
        return prevState + 1;
      });
    }
    if (e.currentTarget.id === 'decrement') {
      setPage((prevState) => {
        if (prevState === 1) return 1;
        return prevState - 1;
      });
    }
  }
  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    async function getData() {
      try {
        const data = await APIFeatures.getDataWithPagination(
          {
            apiLink: apiLink,
            limit: limit,
            page: page,
          },
          source
        );
        setTotalInfo({
          skip: (page - 1) * limit,
          maxPages: Math.ceil(data.data.totalCount / limit),
          total: data.data.totalCount,
        });

        setData(data.data.data);
        setCurrentState(PageState.LOADED);
      } catch (err) {
        if (axios.isCancel(err)) return;
        setCurrentState(PageState.ERROR);
      }
    }
    getData();
    return () => {
      source.cancel();
    };
  }, [limit, page, apiLink]);
  return (
    <>
      <div className='card-body' id='table-card-control'>
        <div className='row d-flex align-items-center'>
          <div className='col-md-3 text-nowrap'>
            <div className='m-1'>
              <label className='m-0'>
                {t('pagination:show')}&nbsp;
                <select
                  className='form-control form-control-sm custom-select custom-select-sm'
                  value={limit}
                  onChange={(e) => {
                    setPage(1);
                    setLimit(Number(e.target.value));
                  }}
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                </select>
              </label>
            </div>
          </div>
          <div className='col-md-12 col-lg-6'>
            <div
              className='text-start text-md-end d-flex justify-content-center dataTables_filter'
              id='dataTable_filter'
            >
              <label className='flex-fill m-1'>
                <input
                  type='search'
                  disabled
                  className='form-control form-control-sm'
                  aria-controls='dataTable'
                  placeholder={t('pagination:search')}
                />
              </label>
            </div>
          </div>
          <div className='col-md-12 col-lg-3 text-end'> {props.button} </div>
        </div>
      </div>
      {currentState === PageState.LOADED ? (
        props.children
      ) : currentState === PageState.LOADING ? (
        <HeartSpinnerTable />
      ) : (
        <GlobalError />
      )}
      <div
        className='card-body'
        id='table-card-footer'
        style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
      >
        <div className='row'>
          <div className='col-md-6 align-self-center'>
            {i18n.language === 'en' ? (
              <p className='m-0'>
                Showing {totalInfo.skip + 1} to{' '}
                {totalInfo.skip + limit > totalInfo.total
                  ? totalInfo.total
                  : totalInfo.skip + limit}{' '}
                of {totalInfo.total}
              </p>
            ) : (
              <p className='m-0'>
                عرض من {totalInfo.skip + 1} إلى{' '}
                {totalInfo.skip + limit > totalInfo.total
                  ? totalInfo.total
                  : totalInfo.skip + limit}{' '}
                من أصل {totalInfo.total}
              </p>
            )}
          </div>
          <div className='col-md-6 d-flex justify-content-end'>
            <button
              id='decrement'
              disabled={page <= 1}
              style={{ border: 'none', background: '#FFFF' }}
              onClick={handlePagination}
            >
              <i className='material-icons d-flex  pagination-arrow'>
                {i18n.language === 'en'
                  ? `keyboard_arrow_left`
                  : `keyboard_arrow_right`}
              </i>
            </button>
            <div className='pagination-btn'>
              <h5 className='m-0'>
                {page}/{totalInfo.maxPages}
              </h5>
            </div>
            <button
              id='increment'
              disabled={page >= totalInfo.maxPages}
              style={{ border: 'none', background: '#FFFF' }}
              onClick={handlePagination}
            >
              <i className='material-icons d-flex pagination-arrow'>
                {i18n.language === 'en'
                  ? `keyboard_arrow_right`
                  : `keyboard_arrow_left`}
              </i>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
